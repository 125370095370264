import React, { useEffect, useState } from 'react'
import '../signupApp/SignupApp.css'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { DepttsLogin } from '../../redux/services/DepttUser';
import { clearState } from '../../redux/features/DepttUserSlice';
import { setActiveUser } from '../../redux/features/UserSlice';

const DepttLogin = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    const checkEmail = () => {
        if (!email) {

            return false
        } else if (!regex.test(email)) {

            return false
        } else {
            return true
        }

    }
    const checkPassword = () => {
        if (!password) {
            return false
        } else {
            return true
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()


        if (!checkEmail() || !checkPassword()) {
            return toast.error("Please Enter Email & Password");
        }

        dispatch(DepttsLogin({ email, password }))

    }

    const user = useSelector(state => state.DepttsLogin)
    // console.log(user)
    const { errorMessage, loginSuccessfull, sucessfullMessage, loader, isError } = user

    useEffect(() => {
        if (loginSuccessfull) {
            // console.log('this is succesfull message useeffect')
            // alert('successfull login')
            toast.success(sucessfullMessage);
            const user = JSON.parse(localStorage.getItem("userdetail"))
            dispatch(setActiveUser(user))
            navigate('/deptthome')
            dispatch(clearState())
        }
        if (isError) {
            toast.error(errorMessage);
            dispatch(clearState())


        }
    }, [loginSuccessfull, isError]);

    return (
        <div className='continer-fluid loginappfulldiv'>
            <ToastContainer />
            <div className='signupformfulldiv '>
                <div className='signupformdiv'>
                    <div className='row'>
                        <div className='col-12 mt-4'>
                            <h3>Departments Login</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='col-12 mt-4 '>
                                <div className='inputfulldivsignup'>
                                    <div className='col-3'>
                                        <p className='inputtextsignup '>Email </p>
                                    </div>
                                    <div className='col-7'>
                                        <input className='inputfieldsignupform' type="text" name='email'
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            disabled={(loader) ? "disabled" : ""}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mt-4 '>
                                <div className='inputfulldivsignup'>
                                    <div className='col-3'>
                                        <p className='inputtextsignup '>Password </p>
                                    </div>
                                    <div className='col-7'>
                                        <input className='inputfieldsignupform' type="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            disabled={(loader) ? "disabled" : ""}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div>
                                <button className='btn btn-primary mt-3 mb-3' type='submit'>
                                    {loader ? "loading.." : "Login"}

                                </button>
                            </div>
                        </form>
                        {/* <div>
                            <p><Link to='#'>Forgot Password</Link> <br /> if you don't register first <Link to='/signup'>SignUp</Link></p>
                        </div> */}


                    </div>
                </div>

            </div>

        </div>
    )
}

export default DepttLogin




