import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div className='container-fluid footerfulldiv' style={{ marginTop: '100px' }}>
            <div className='row footerrowfulldiv'>
                <div className='col-lg-4 col-md-4 col-sm-4 mt-3 d-flex flex-column '>
                    <h4 className='headingfooterlink'>Quick Links</h4>
                    <a href="http://www.uoswabi.edu.pk/announcements/archives" className=' footerlink'>Announcements</a>
                    <a href="http://www.uoswabi.edu.pk/cmscategory/index/32" className=' footerlink'>Provost Office</a>
                    <a href="http://www.uoswabi.edu.pk/cms/index/391" className=' footerlink'> ORIC</a>
                    <a href="http://www.digitallibrary.edu.pk/Uniofswa.html" className=' footerlink'> HEC Digital Library</a>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 mt-3 d-flex flex-column'>
                    <h4 className='headingfooterlink'> Links</h4>
                    <a href="http://www.uoswabi.edu.pk/cmscategory/index/13" className=' footerlink'>Private Program</a>
                    <a href="http://www.uoswabi.edu.pk/cmscategory/index/12" className=' footerlink'>Affiliations</a>
                    <a href="http://www.uoswabi.edu.pk/cmscategory/index/14" className=' footerlink'> Proctorial Board</a>
                    <a href="http://www.uoswabi.edu.pk/cmscategory/index/15" className=' footerlink'> Directorate of Sports</a>
                </div>
                <div className='col-lg-4 col-md-4 col-sm-4 mt-3'>
                    <h4 className='headingfooterlink'>Contact Us</h4>
                    <p className=''>UOS Exchange<br />Tel No: (+92-938) 490500<br />Email: webmaster@uoswabi.edu.pk</p>


                    <p className=''>Registrar<br />Tel No: (+92-938) 490236 <br /> Email: registrar@uoswabi.edu.pk</p>

                </div>

                <div className='row' >
                    <div className='text-center'>
                        <p className='footertext'>Developed By :<br />Aqib Hassan Zeb $ Wajahat Ullah</p>
                    </div>
                    <div className='text-center'>
                        <p className='footertext'>Copyright © 2022 UOSwabi. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer