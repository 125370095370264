import { createSlice } from '@reduxjs/toolkit'
import { DepttApplist } from '../services/DepttApplist';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
    datalist: [],
    errorMessage: "",
    loader: false,
    sucessfull: false,
    isError: false,
}

const DepttApplistSlice = createSlice({
    name: "depttapplist",
    initialState,
    reducers: {
        clearState: (state, action) => {

            return initialState
        },

    },
    extraReducers: {
        [DepttApplist.fulfilled]: (state, action) => {
            state.loader = false
            if (action.payload === undefined) {
                // toast.error('Server down')
                state.errorMessage = "Server down"
                state.isError = true
                // console.log('undefined')
            }

            else if (action.payload.error) {
                state.isError = true
                state.errorMessage = action.payload.error
                // toast.error(action.payload.error)
                // console.log("payload error", action.payload.error)
            }
            else if (action.payload.message) {

                // state.sucessfullMessage = action.payload.message
                state.sucessfull = true
                state.datalist = action.payload.applicants
                // console.log(action.payload.message)
                // toast.success(action.payload.message)
            }
            else {
                toast.error('Internet issue')
                // console.log("internet isssue")
            }
        },

        [DepttApplist.pending]: (state) => {
            state.loader = true;
        },

    }



});

export const { clearState } = DepttApplistSlice.actions

export default DepttApplistSlice.reducer