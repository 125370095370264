import React from 'react'
import "./Aboutus"
import { Table } from 'react-bootstrap'
const BsProgram = () => {
  var Sno = 0;
  const departments = [
    {
      name: "BS in Agriculture",
      duration: " 4 Years "
    },
    {
      name: "BS in Biotechnology",
      duration: " 4 Years "
    },
    {
      name: "BS in Botany",
      duration: " 4 Years "
    },
    {
      name: "BS in Chemistry",
      duration: " 4 Years "
    },

    {
      name: "BS in Computer Science",
      duration: " 4 Years "
    },
    {
      name: "BS in Economics",
      duration: " 4 Years "
    },
    {
      name: "BS in Education",
      duration: " 4 Years "
    },
    {
      name: "BS in English",
      duration: " 4 Years "
    },
    {
      name: "BS in Environmental Sciences",
      duration: " 4 Years "
    },
    {
      name: "BS in Geography",
      duration: " 4 Years "
    },
    {
      name: "BS in Geology",
      duration: " 4 Years "
    },
    {
      name: "BS in Islamic & Arabic Studies",
      duration: " 4 Years "
    },
    {
      name: "BS in Journalism & Mass Communication",
      duration: " 4 Years "
    },
    {
      name: "BS in Law",
      duration: " 5 Years "
    },
    {
      name: "BS in Library & Information Sciences",
      duration: " 4 Years "
    },
    {
      name: "BS in Management Sciences",
      duration: " 4 Years "
    },
    {
      name: "BS in Mathematics",
      duration: " 4 Years "
    },
    {
      name: "BS in Microbiology",
      duration: " 4 Years "
    },
    {
      name: " BS in PCRS",
      duration: " 4 Years "
    },
    {
      name: "Pharm-D",
      duration: " 5 Years "
    },
    {
      name: "BS in Physics",
      duration: " 4 Years "
    },
    {
      name: "BS in Political Science",
      duration: " 4 Years "
    },
    {
      name: "BS in Psychology",
      duration: " 4 Years "
    },
    {
      name: "BS in Sociology",
      duration: " 4 Years "
    },
    {
      name: "BS in Tourism & Hotel Management",
      duration: " 4 Years "
    },
    {
      name: "BS in Urdu",
      duration: " 4 Years "
    },
    {
      name: "BS in Zoology",
      duration: " 4 Years "
    },
  ]
  return (
    <div>
      <div className='container-fluid allaboutusbanerfulldiv' >
        <div className='allaboutbanertext'>
          <h1>BS PROGRAMS</h1>
        </div>
      </div>

      <div className='container'>
        <div className='directorheadingaboutus mt-5'>
          <h4 style={{ textTransform: "uppercase" }}>
            BS 16 Years Undergraduate Programmes
          </h4>
        </div>
        <div className='directorparaaboutus mt-4'>
          <p>
            All BS level programmes consist of eight (08) semesters that is spread over four (04) years. The number of Credit Hours (CrHr) is varying from programme to programme but in most cases, it is 130 or more for a four years undergrad programme. However, there are certain bachelor programmes of five-year duration. The five-year bachelor programme normally having more than 150 CrHr. Programme which is accredited or recognized by any accreditation council follow the criteria set by the respective council. List of available Undergraduate Programmes are listed below:.
          </p>
        </div>
        <div className='mt-5'>

          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Programme Title</th>
                <th>Programme Duration</th>

              </tr>
            </thead>
            <tbody>
              {
                departments.map((item, index) =>
                  <tr key={index} >
                    <td >{Sno = Sno + 1}</td>
                    <td >{item.name}</td>
                    <td >{item.duration}</td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>

      </div>
    </div>
  )
}

export default BsProgram