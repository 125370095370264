import React from 'react'
import { useNavigate } from 'react-router-dom'

const AdminDepttloginpath = () => {
    const navigate = useNavigate()
    return (
        <div className='text-center' style={{ marginTop: '200px' }}>
            <button className='btn btn-success' onClick={() => navigate('/adminlogin$$$*$$$')} >Admin Login</button>
            <br />
            <button className='btn btn-primary' style={{ marginTop: '50px' }} onClick={() => navigate('/depttlogin$$$*$$$')}>Departments Login</button>
        </div>
    )
}

export default AdminDepttloginpath