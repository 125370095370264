import { createSlice } from '@reduxjs/toolkit'
import { AppLogin, AppReg } from '../services/AppUser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const initialState = {

    errorMessage: "",
    sucessfullMessage: "",
    loader: false,
    sucessfull: false,
    isError: false,
    token: "",
    userdetail: "",
    loginSuccessfull: false
}

const AuthSlice = createSlice({
    name: "ApplicantsUser",
    initialState,
    reducers: {
        clearState: (state, action) => {
            // Object.assign(state, action.payload)
            return initialState
        },
    },
    extraReducers: {
        [AppReg.pending]: (state, action) => {
            state.loader = true
        },
        [AppReg.fulfilled]: (state, action) => {
            state.loader = false

            if (action.payload === undefined) {
                toast.error('Server down')
            } else if (action.payload.message) {
                state.sucessfull = true
                state.sucessfullMessage = action.payload.message
                // toast(action.payload.message)

                // console.log('this is message in appuserslice')
            }
            else if (action.payload.error) {
                state.isError = true
                state.errorMessage = action.payload.error
                // toast(action.payload.error)
                // console.log(action.payload.error)
            }
            else {
                toast.error('internet issue')
            }

        },

        // this is login portion

        [AppLogin.fulfilled]: (state, action) => {
            state.loader = false

            if (action.payload === undefined) {
                toast.error('Server down')
                // console.log('undefined')
            }

            else if (action.payload.error) {
                state.isError = true
                state.errorMessage = action.payload.error
                // toast(action.payload.error)
                // console.log("payload error", action.payload.error)
            }
            else if (action.payload.message) {
                state.loginSuccessfull = true
                state.token = action.payload.token
                state.userdetail = action.payload.AppUser
                toast.success("successfull login")
            }
            else {

                toast.error('internet issue')
            }
        },

        [AppLogin.pending]: (state) => {
            state.loader = true;
        },

    }

});



export const { ApplicantsReg, clearState } = AuthSlice.actions



export default AuthSlice.reducer