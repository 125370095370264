import { createSlice } from '@reduxjs/toolkit'
import { ApplyForm } from '../services/ApplyForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
    errorMessage: "",
    sucessfullMessage: "",
    loader: false,
    sucessfull: false,
    isError: false,
    depttsDatas: []
}

const ApplyFormSlice = createSlice({
    name: "ApplyForm",
    initialState,
    reducers: {
        clearState: (state, action) => {

            return initialState
        },
        formDeptts: (state, action) => {
            // console.log(state, "this is state console"),
            state.depttsDatas = action.payload
        }
    },

    extraReducers: {
        [ApplyForm.fulfilled]: (state, action) => {
            state.loader = false
            // console.log("this is action payload", action.payload)
            if (action.payload === undefined) {
                toast.error('Server down')
                // console.log('undefined')
            }

            else if (action.payload.error) {
                state.isError = true
                state.errorMessage = action.payload.error
                // toast.error(action.payload.error)
                // console.log("payload error", action.payload.error)
            }
            else if (action.payload.message) {

                state.sucessfullMessage = action.payload.message
                state.sucessfull = true
                sessionStorage.clear();

                // console.log(action.payload.message)
                // toast.success(action.payload.message)
            }

        },

        [ApplyForm.pending]: (state) => {
            state.loader = true;
        },

    }



});

export const { clearState, formDeptts } = ApplyFormSlice.actions

export default ApplyFormSlice.reducer