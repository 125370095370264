import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../../config"
const ResetPassword = () => {



    const [email, setEmail] = useState('')
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()

    const handlesubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            toast("Invalid email !");
            return
        }
        fetch(`${API_URL}/reset-password`, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email
            })
        }).then(res => res.json())
            .then(data => {
                // console.log(data)
                setLoader(false)
                if (data.error) {
                    toast.error(data.error)
                } else {
                    // toast.success(data.message)
                    alert("Check your email for password reset link")
                    navigate('/applogin')
                }
            }).catch(err => {
                setLoader(false)
                toast.error('server down')
                // console.log(err)
            })
    }
    return (
        <div className='container'>
            <ToastContainer />
            <div className='row text-center' style={{ marginTop: '150px' }}>
                <h3 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>Reset Password</h3>
            </div>
            <div className='row mt-5 d-flex justify-content-center'>
                <div className='col-lg-6 col-sm-12'>
                    <div className="formdivnews">
                        <form onSubmit={handlesubmit}>
                            <label>Enter your email</label>
                            <input type="email" placeholder="john@gmail.com.." className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}

                            />
                            {loader ? <button className="btn btn-primary float-end" disabled>Loading...</button> :
                                <button className="btn btn-primary float-end" type='submit'>Submit</button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword