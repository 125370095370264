import React from 'react'
import "./Aboutus.css"
const Aboutus = () => {
  return (
    <div >
      <div className='container-fluid allaboutusbanerfulldiv' >
        <div className='allaboutbanertext '>
          <h1>ABOUT US</h1>
        </div>
      </div>
      <div className='container'>
        <div className='directorheadingaboutus mt-5'>
          <h4>
            DIRECTORATE OF ADMISSIONS
          </h4>

        </div>
        <div className='directorparaaboutus mt-4'>
          <p>
            The office of the directorate of admissions works under the Director Admissions.
            After the establishment of University of Swabi, Admission section came into existence
            for advertising admissions in various departments running BS, MS, M.Phill, and Ph.D.
            programmes. Forms are received in Directorate of Admissions,
            processed and forwarded to the departments for the applied disciplines.
          </p>
        </div>
        <h4>
          Function of the Directorates
        </h4>
        <div>
          <li>
            To manage Publications of University Prospectus under Graduate and Postgraduate programs.
          </li>
          <li>
            To finalized admission schedules and give them wide publicity.
          </li>
          <li>To collect and process application forms received for admission various programs.</li>
          <li>To coordinate with concerned departments in matters relating to admissions.</li>
          <li>To ensure compliance with admission standard policies and regulation.</li>
        </div>
        <h4 className='mt-5'>
          Admission policy
        </h4>
        <p className='mt-3'>
          The University of Swabi offers admissions in BS level program in Fall semester each year, while the post graduate admissions are announced in spring semester each year
        </p>
        <h4 className='mt-5'>
          Documents required for seeking admission
        </h4>
        <p className='mt-3'>
          The following attested documents are required during admission process
        </p>
        <div>
          <li>
            Three Passport Size Photographs (Attested).
          </li>
          <li>
            Attested copies of all Credentials.
          </li>
          <li>Copy of CNIC/Domicile. (Attested).</li>
          <li>Migration certificate of the last institute attended.</li>
        </div>
      </div>
    </div>
  )
}

export default Aboutus