import React, { useEffect, useState } from "react";
import { Carousel, Modal, Button } from "react-bootstrap";
import "./SliderClass.css";
import pic1 from "../../assets/pic1.jpg";
import pic2 from "../../assets/pic2.jpg";
import pic3 from "../../assets/pic3.jpg";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../../config"



export default function SliderClass() {
    const [show, setShow] = useState(false);
    const handleClose = () => {

        setShow(false);
    }
    const handleShow = () => { setShow(true); }

    const [name, setName] = useState('')
    const [inputemail, setInputemail] = useState('')
    const [phone, setPhone] = useState('')
    const [messagetext, setMessagetext] = useState('')
    const [loader, setLoader] = useState(false)
    // console.log(loader)
    useEffect(() => {
        // console.log('useeffect')
        setName('')
        setInputemail('')
        setPhone('')
        setMessagetext('')
    }, [show])
    const submitHandler = (e) => {
        e.preventDefault();

        if (name === '' || inputemail === '' || phone === '' || messagetext === '') {
            return toast.error("Please fill all the fields")
        }
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputemail)) {
            toast.error("Invalid email !");
            return
        }

        // console.log(name, inputemail, phone, messagetext);
        setLoader(true)
        fetch(`${API_URL}/contactuswrite`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem("token")
            },

            body: JSON.stringify({
                name, inputemail, phone, messagetext,
            })
        })
            .then(response => {
                // console.log(response.status)
                if (response.ok) {
                    return response.json();
                }
                // if (response.status === 500) {
                //     response.json()
                //         .then(data =>
                //             toast.error(data.message)

                //         );



                //     return
                // }
                throw new Error(
                    setLoader(false),
                    toast.error("Server down")


                )
            })
            .then(data => {
                setLoader(false)
                toast.success(data.message)

                setShow(false)

            },
            ).catch(err => {
                setLoader(false)
                // toast.error("Server down")
            })

    }



    return (<div >
        <ToastContainer />
        <Carousel fade>
            <Carousel.Item>
                <img
                    className="d-block w-100 sliderpicmain"
                    src={pic1}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <div className="sliderbtnsendrequestdiv">
                        <Button onClick={handleShow} className="sliderbtnsendrequest">
                            Contact Us
                        </Button>

                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 sliderpicmain"
                    src={pic2}
                    alt="Second slide"
                />

                <Carousel.Caption>
                    <div className="sliderbtnsendrequestdiv">
                        <Button onClick={handleShow} className="sliderbtnsendrequest">
                            Contact Us
                        </Button>

                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100 sliderpicmain"
                    src={pic1}
                    alt="Third slide"
                />

                <Carousel.Caption>
                    <div className="sliderbtnsendrequestdiv">
                        <Button onClick={handleShow} className="sliderbtnsendrequest">
                            Contact Us
                        </Button>

                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Contact Us !</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e) => submitHandler(e)}>
                    <input
                        type="text"
                        className="form-control mb-3  custom"
                        id="exampleInputEmail1"
                        placeholder="Name *"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <input
                        type="email"
                        className="form-control mb-3 custom"
                        id="exampleInputEmail1"
                        placeholder="E-mail *"
                        required
                        value={inputemail}
                        onChange={(e) => setInputemail(e.target.value)}
                    />

                    <input
                        type="number"
                        id="phone"
                        className="form-control mb-3 custom"
                        //   id="exampleInputEmail1"
                        placeholder="Phone no "
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />


                    <textarea
                        className="form-control textarea mb-3 custom"
                        id="exampleFormControlTextarea1"
                        placeholder="Message *"
                        required
                        value={messagetext}
                        onChange={(e) => setMessagetext(e.target.value)}
                    />
                    {loader ? <button className="btn-success float-right " disabled>
                        Loading...
                    </button> :
                        <button className="btn-success float-right " type="submit">
                            Submit
                        </button>
                    }
                </form>

            </Modal.Body>


        </Modal>
        <div className="sliderbelowdivslider">
            <div className="sliderbelowdivsliderbtndiv">

                <Button onClick={handleShow} className="sliderbelowdivsliderbtn">
                    Contact Us
                </Button>

            </div>


        </div>

    </div>
    );
}






