

import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx'
import { API_URL } from "../../config"
const TotalApp = () => {

    const [datalist, setDatalist] = useState([])
    const [loader, setLoader] = useState(false)
    const [depttlist, setDepttlist] = useState([])
    const [applist, setApplist] = useState([])
    // console.log(applist)
    const [depttname, setDepttname] = useState('All')
    // console.log(depttname)
    var list = depttlist && depttlist.slice()

    var newlistdeptt = depttlist && list.sort((a, b) => a.depttname.localeCompare(b.depttname));
    var newlist = applist && applist.slice()

    var newlistapp = newlist && newlist.sort((a, b) => a.name.localeCompare(b.name));

    useEffect(() => {


        fetch(`${API_URL}/adminregisterdeptt`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },

        })
            .then(response => response.json())
            .then(data =>

                setDepttlist(data.resp)

            );


    }, [])

    const handleSubmit = () => {
        // console.log('handlesubmit')
        setLoader(true)
        fetch(`${API_URL}/adminapplyapplicant`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify({
                depttname
            })

        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setLoader(false),
                    toast.error("Server down")

                )
            })
            .then(data => {
                setApplist(data.resp)
                setLoader(false)
            },
            );

    }

    var serialNo = 0;
    useEffect(() => {
        handleSubmit();


    }, [])
    // console.log(applist)
    // console.log(applist.length)
    const handleExport = () => {

        // console.log('handle export')

        var wb = XLSX.utils.book_new()

        // const myHeader = [{ _id: 'Form No', name: 'Name', fatherName: 'F/Name', contactno: 'Contact No' }]
        const myHeader = ["_id", "name", "fatherName", "interObtmarks", "matricObtmarks", 'aggregate', 'formStatus', 'session', 'contactno', 'email']
        // newlist.unshift(myHeader);

        const ws = XLSX.utils.json_to_sheet(applist, { header: myHeader });
        ws['!cols'] = [{ width: 25 }, { width: 20 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 10 }, { width: 15 }
            , { width: 15 }, { width: 15 }, { width: 15 }
        ];
        const range = XLSX.utils.decode_range(ws['!ref']);
        // console.log(range)
        const abc = range.e['c'] = myHeader.length - 33;
        // console.log(abc)
        ws['!ref'] = XLSX.utils.encode_range(range);
        // console.log(ws['!ref'])
        XLSX.utils.book_append_sheet(wb, ws, `${depttname}Applicantslist`);
        XLSX.writeFile(wb, `${depttname}Applicantslist.xlsx`);
    }


    return (
        <div className='container'>
            <ToastContainer />
            <div className='' style={{ marginTop: '100px', marginBottom: '200px' }}>
                <div className='text-center'>
                    <h1 className='mb-5' style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>All Applicants List</h1>
                </div>

                <div className='d-flex justify-content-end'>
                    <div className='mt-3' >
                        <select aria-label="Default select example"
                            style={{ height: '40px', width: '140px', borderRadius: '5px', border: '1px solid #003396', padding: '0px 10px', fontSize: '14px', color: '#003396' }}
                            value={depttname} onChange={(e) => setDepttname(e.target.value)}>
                            <option value="All"> All</option>
                            {newlistdeptt && newlistdeptt.map((res) => (
                                <option key={res._id} value={res.depttname}>
                                    {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='mt-3' >
                        <button className="btn-primary" style={{ height: '40px', width: '140px', borderRadius: '5px', border: '1px solid #003396', padding: '0px 10px', fontSize: '14px' }} onClick={handleSubmit}>
                            {loader ? "loading.." : "Show Applicants"}
                        </button>

                    </div>
                </div>
                <div className='d-flex justify-content-end mt-5'>
                    {

                        applist.length == 0 ? <p>-</p> : <Button onClick={() => window.confirm('Are you sure to download this applicants list?',) && handleExport()}>Download</Button>


                    }
                </div>
                {loader ? <div className='' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ThreeDots color="#003396" height={80} width={80} />

                </div> :
                    <Table responsive hover variant="ligth"
                        id='test-table-xls'
                    >
                        <thead>
                            <tr>
                                <th>S/No</th>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>SSC Marks</th>
                                <th>HSSC Marks</th>
                                <th>Aggregate</th>
                                <th>Form Status</th>
                                <th>Detail</th>


                            </tr>
                        </thead>
                        <tbody>
                            {newlistapp ? newlistapp.map((elem) =>
                                <tr key={elem._id}>
                                    <td>{serialNo = serialNo + 1}</td>
                                    <td>{elem.name}</td>
                                    <td>{elem.fatherName}</td>
                                    <td>{elem.matricObtmarks}</td>
                                    <td>{elem.interObtmarks}</td>
                                    <td>{elem.aggregate.toFixed(2)}</td>
                                    <td>{elem.formStatus.charAt(0).toUpperCase() + elem.formStatus.slice(1)}</td>
                                    <td>
                                        <Link to={`/appdetail/${elem._id}`}>
                                            View Detail
                                        </Link></td>
                                </tr>
                            ) : null}
                        </tbody>
                    </Table>}
            </div>
        </div >
    )
}

export default TotalApp