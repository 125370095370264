import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { API_URL } from "../../config"
const RegisterApplist = () => {

    const [datalist, setDatalist] = useState([])
    const [loader, setLoader] = useState(false)
    const [datafetchcontrol, setDatafetchcontrol] = useState(false)
    var list = datalist && datalist.slice()
    var newlist = datalist && list.sort((a, b) => a.name.localeCompare(b.name));

    var serialNo = 0;
    useEffect(() => {
        setLoader(true)
        fetch(`${API_URL}/adminregisterapp`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },


        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setLoader(false),
                    console.log('error')

                )
            })
            .then(data => {
                setDatalist(data.resp)
                setLoader(false)
            },
            ).catch(err => {
                setLoader(false)
                toast.error("Server down")
            })


    }, [datafetchcontrol])

    const deleteHandler = (_id) => {
        setLoader(true)
        fetch(`${API_URL}/admindeleteregapp/${_id}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },

        }).then(res => res.json())
            .then(data => {
                setLoader(false)
                // console.log(data)
                if (data.error) {
                    toast.error(data.error)
                } else {
                    toast.success(data.message)
                    setDatafetchcontrol(!datafetchcontrol)
                }
            }).catch(err => {
                setLoader(false)
                toast.error('server down')
                // console.log(err)
            })

    }

    return (
        <div className='container'>
            <ToastContainer />
            <div className='' style={{ marginTop: '100px', marginBottom: '200px' }}>
                <div className='text-center'>
                    <h1 className='mb-5' style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>Register Applicants list</h1>
                </div>
                {loader ? <div className='' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ThreeDots color="#003396" height={80} width={80} />

                </div> :
                    <Table responsive hover variant="ligth"
                        id='test-table-xls'
                    >
                        <thead>
                            <tr>
                                <th>S/No</th>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>CNIC</th>
                                <th>Contact No</th>
                                <th>Email</th>
                                <th>Delete</th>


                            </tr>
                        </thead>
                        <tbody>
                            {newlist ? newlist.map((elem) =>
                                <tr key={elem._id}>
                                    <td>{serialNo = serialNo + 1}</td>
                                    <td>{elem.name}</td>
                                    <td>{elem.fatherName}</td>
                                    <td>{elem.cnic}</td>
                                    <td>0{elem.contactno}</td>
                                    <td>{elem.email}</td>
                                    <td>
                                        <button className='btn btn-danger' onClick={() => {
                                            window.confirm('Are you sure you want to delete this Register Applicants?',)
                                                && deleteHandler(elem._id)
                                        }}>Delete</button>
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </Table>}
            </div>
        </div>
    )
}

export default RegisterApplist