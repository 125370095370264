import React from 'react'
import './Unidocumentation.css'
import picture11 from '../../assets/uni11.jpg'
import picture12 from '../../assets/uni12.jpg'
import picture13 from '../../assets/uni13.jpg'
import picture14 from '../../assets/uni14.jpg'
import picture15 from '../../assets/uni15.jpg'

const Unidocumentation = () => {
    return (
        <div className='container'>
            <div className='d-flex justify-content-center' style={{ marginTop: '150px' }}>
                <h4 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}> Eligiblity for Departments</h4>
            </div>
            <div className='row mt-5'>
                <p>You should know about th eligiblity of department ,Are you can apply for specific are not
                    if you apply department, and you are not eligible for this department your apply should not consider for
                    this department please visit here for all department details<a href='https://www.uoswabi.edu.pk/cmscategory/index/3'>click here</a>
                </p>
            </div>
            <div className='d-flex justify-content-center' style={{ marginTop: '150px' }}>
                <h4 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}> Detail for Apply</h4>
            </div>
            <div className=' mt-5 pt-5'>
                <div className='row'>
                    <div className='col-lg-6  col-sm-12  mt-2 '>
                        <h1 className='text-center'>Signup page</h1>
                        <p className=''>
                            First go to signup option and write name and your father name in capital letters.After
                            that ,give valid email along with strong password as it would help you in the long run.
                        </p>
                    </div>
                    <div className='col-lg-6  col-sm-12 d-flex justify-content-center mt-2'>

                        <img className='imageinwifisolution' src={picture11} />

                    </div>
                </div>


                <div className='row mt-5'>

                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                        <h1 className='text-center'>Login page</h1>
                        <p className='Textattractiondiv'>
                            Then go to the login page and give password valid also valid email.
                            if you have Forgotten your password then click on the forgot password link.
                            if you have not registered yet then click on the signup link.



                        </p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mt-2'>
                        <img className='imageinwifisolution' src={picture12} />
                    </div>
                </div>
                <div className='row mt-5' >

                    <div className='col-lg-6 col-md-6 col-sm-12 mt-2'>
                        <h1 className='text-center'>Admission form</h1>
                        <p className='Textattractiondiv'>
                            Then go to admission form and upload your picture in proper format as you will not change it if it is not properly uploaded. Furthermore, in the same format upload your CNIC number and all relevant documents.
                        </p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mt-2'>
                        <img className='imageinwifisolution' src={picture13} />
                    </div>
                </div>
                <div className='row mt-5'>

                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h1 className='text-center'>Department way</h1>
                        <p className='Textattractiondiv'>
                            Afterwards, select not less than 1 department and not more than 5 departments. Also look Intermediate eligiblity criteria according to that select your department otherwise your apply will not be entertained by the Department.
                            after that write your academic valid details and also should your documents be uploaded properly.
                            If you have hafiz Quran then click on hafiz Quran portion yes otherwise no.Aslo mind this your apply for hafiz Quran will not be entertained by the Department even your enterview will not be entertained by the Department.
                            and see your wifaq hifaz Certificate after that you got 20 marks .This marks will be added with your Intermediate marks and then you will get your final marks.
                            And this form take few seconds in submitting so please be patient.If you are already apply then you canno't apply again
                            for further questions please contact us on our valid email address.
                        </p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mt-2'>
                        <img className='imageinwifisolution' src={picture14} />
                    </div>
                </div>
                <div className='row mt-5'>

                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h1 className='text-center'>Any Question</h1>
                        <p className='Textattractiondiv'>
                            Give your name and valid email as you will be contacted through this medium and also do give phone number in use and write a solid message at the end.                      </p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mt-2'>
                        <img className='imageinwifisolution' src={picture15} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Unidocumentation