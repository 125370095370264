import { configureStore } from '@reduxjs/toolkit'
import AdminUserSlice from './features/AdminUserSlice'
import ApplyFormSlice from './features/ApplyFormSlice'
import AppUserSlice from './features/AppUserSlice'
import DepttApplistSlice from './features/DepttApplistSlice'
import DepttUserSlice from './features/DepttUserSlice'
import UserSlice from './features/UserSlice'

export const store = configureStore({
    reducer: {
        AppReg: AppUserSlice, ApplyForm: ApplyFormSlice,
        DepttsLogin: DepttUserSlice, AdmLogin: AdminUserSlice,
        DepttApplist: DepttApplistSlice, user: UserSlice
    },
})