import React, { useState } from "react";
import "./ContactUs.css";
import contactimglogo from '../../assets/undraw-contact.svg'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../../config"
const ContactUs = () => {
    const [name, setName] = useState('')
    const [inputemail, setInputemail] = useState('')
    const [phone, setPhone] = useState('')
    const [messagetext, setMessagetext] = useState('')
    const [loader, setLoader] = useState(false)

    const submitHandler = (e) => {
        e.preventDefault();

        if (name === '' || inputemail === '' || phone === '' || messagetext === '') {
            return toast.error("Please fill all the fields")
        }
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputemail)) {
            toast.error("Invalid email !");
            return
        }

        // console.log(name, inputemail, phone, messagetext);
        setLoader(true)
        fetch(`${API_URL}/contactuswrite`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem("token")
            },

            body: JSON.stringify({
                name, inputemail, phone, messagetext,
            })
        })
            .then(response => {
                // console.log(response.status)
                if (response.ok) {
                    return response.json();
                }
                // if (response.status === 500) {
                //     response.json()
                //         .then(data =>
                //             toast.error(data.message)

                //         );



                //     return
                // }
                throw new Error(
                    setLoader(false),
                    toast.error("Server down")


                )
            })
            .then(data => {
                setLoader(false)
                toast.success(data.message)
                setName('')
                setInputemail('')
                setPhone('')
                setMessagetext('')
            },
            ).catch(err => {
                setLoader(false)
                // toast.error("Server down")
            })


    }


    return (
        <>
            <div className="container-fluid " style={{ marginTop: '100px' }}>
                <ToastContainer />
                <div className="row ">
                    <div className="col">
                        {/* <div className="textquestionheading">
                        <h5 className="getintouchtextheading">// GET IN TOUCH WITH US</h5>
                    </div> */}
                        <div className="textquestionheading">
                            <h2 className="anytextmessagequestion">
                                {" "}
                                Any Question or Remarks? Just write us message!
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="imageandtextformfulldivquestion">
                    <div className="container row mt-3 ">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 imagedivquestion">
                            <h3 className="imagetextquestiondiv">Send us a Message </h3>
                            <p className="imagetextquestiondiv">
                                Fill up the form and send
                                <br /> ask your questions
                            </p>
                            <img src={contactimglogo} className="img-fluid  " alte="contact" />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 pt-5 formdivquestion">
                            <form onSubmit={(e) => submitHandler(e)}>
                                <input
                                    type="text"
                                    className="form-control mb-3  custom"
                                    id="exampleInputEmail1"
                                    placeholder="Name *"
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />

                                <input
                                    type="email"
                                    className="form-control mb-3 custom"
                                    id="exampleInputEmail1"
                                    placeholder="E-mail *"
                                    required
                                    value={inputemail}
                                    onChange={(e) => setInputemail(e.target.value)}
                                />

                                <input
                                    type="number"
                                    id="phone"
                                    className="form-control mb-3 custom"
                                    // id="exampleInputEmail1"
                                    placeholder="Phone no "
                                    required
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />


                                <textarea
                                    className="form-control textarea mb-3 custom"
                                    id="exampleFormControlTextarea1"
                                    placeholder="Message *"
                                    required
                                    value={messagetext}
                                    onChange={(e) => setMessagetext(e.target.value)}
                                />
                                {loader ? <button className="mt-5 btn  questionsubmitbtn" disabled>Loading...</button> :
                                    <button className="mt-5 btn  questionsubmitbtn" type="submit">
                                        Submit
                                    </button>}

                            </form>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default ContactUs;

