import Footer from "./components/home/Footer";
import Navbarclass from "./components/navbarclass/Navbarclass";
import RouterClass from "./RouterClass";

function App() {
  return (
    <div>
      <Navbarclass />
      <RouterClass />
      <Footer />
    </div>
  );
}

export default App;
