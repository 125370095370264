import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router'
import './AppDetails.css'
import { ThreeDots } from 'react-loader-spinner';
import { BsPencil } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../config"
const AppDetails = () => {
    let { _id } = useParams();
    const [datalist, setDatalist] = useState('')
    const [show, setShow] = useState(false);
    const [spinner, setSpinner] = useState(false)
    const handleClose = () => {
        setShow(false)
        setSpinner(false)
    }
    const handleShow = () => setShow(true);
    const [picshowhandle, setPicshowhandle] = useState(false)

    const navigate = useNavigate()
    var userdetail = JSON.parse(localStorage.getItem("userdetail"))
    // console.log(datalist, 'userdetail')
    var depttname = userdetail.depttname
    var status = userdetail.status
    var serialNo = 0;
    const deleteCard = (_id) => {



        fetch(`${API_URL}/appdelete/${_id}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify({
                name: datalist.name,
                fatherName: datalist.fatherName,
                cnic: datalist.cnic,
                contactno: datalist.contactno,
                email: datalist.email,
                formStatus: datalist.formStatus,
                session: datalist.session,
                aggregate: datalist.aggregate,

            })

        })
            .then(response => response.json())
            .then((result) => {
                return (

                    // console.log(result.message),
                    navigate('/adminh'),
                    toast.success(result.message)
                )
            }
            );

    }
    useEffect(() => {

        fetch(`${API_URL}/appdetail/${_id}`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            },
            body: JSON.stringify({
                depttname, status
            })

        })
            .then(response => response.json())
            .then(data => setDatalist(data.app),

        );


    }, [show])
    const handleUpdate = () => {
        setSpinner(true)
        fetch(`${API_URL}/appupdate/${_id}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            },
            body: JSON.stringify({
                fatherName, name, cnic, fatherCnic, Dob, matricObtmarks,
                matricTmarks, interObtmarks, interTmarks, hafizQuran, hafizQuranMarks
            })

        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setSpinner(false)

                )
            })

            .then(result => {
                return (

                    // console.log(result.message),
                    toast.success(result.message),
                    setSpinner(false),
                    setShow(false)
                )
            }
            ).catch(err => {
                setSpinner(false)
                console.log(err)
            })
    }

    const [name, setName] = useState(datalist.name)
    const [fatherName, setfatherName] = useState(datalist.fatherName)
    const [cnic, setcnic] = useState(datalist.cnic)
    const [fatherCnic, setfatherCnic] = useState(datalist.fatherCnic)
    const [Dob, setDob] = useState(datalist.Dob)
    const [matricObtmarks, setmatricObtmarks] = useState(datalist.matricObtmarks)
    const [matricTmarks, setmatricTmarks] = useState(datalist.matricTmarks)
    const [interObtmarks, setinterObtmarks] = useState(datalist.interObtmarks)
    const [interTmarks, setInterTmarks] = useState(datalist.interTmarks)
    const [hafizQuran, setHafizQuran] = useState(datalist.hafizQuran)
    const [hafizQuranMarks, setHafizQuranMarks] = useState(datalist.hifazNo)
    useEffect(() => {

        const timer = setTimeout(() => {
            setName(datalist.name);
            setfatherName(datalist.fatherName);
            setcnic(datalist.cnic);
            setfatherCnic(datalist.fatherCnic);
            setmatricObtmarks(datalist.matricObtmarks);
            setmatricTmarks(datalist.matricTmarks);
            setinterObtmarks(datalist.interObtmarks);
            setInterTmarks(datalist.interTmarks);
            setDob(datalist.Dob);
            setHafizQuran(datalist.hafizQuran)
            setHafizQuranMarks(datalist.hifazNo)
            // console.log('useeffect')
        }, 1000);
        return () => clearTimeout(timer);




    }, [datalist, show]);
    const renderListdatashow = () => {
        if (datalist.hafizQuran == "yes") {

            return true
        }
        else {
            return false
        }

    }
    const renderListupdateshow = () => {


        if (datalist && datalist.hafizQuran == "yes") {
            return true
        }
        else {
            return false
        }

    }
    // console.log(datalist && datalist.hifazNo)
    const renderListupdateshow2 = () => {


        if (datalist && datalist.hifazNo == "true") {
            return false
        }
        else {
            return true
        }

    }
    // console.log(datalist)
    return (
        <div className='container'>
            <ToastContainer />
            <div className='' style={{ marginTop: '100px' }}>
                <div className='text-center'>

                    <h1 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>Applicant Detail</h1>
                </div>

                <div className='row'>
                    <div className='d-flex justify-content-end'>
                        {datalist &&
                            <p style={{ textDecoration: 'underline' }}>Email : {datalist.email}</p>
                        }

                    </div>
                    <div className='d-flex justify-content-end'>

                        {datalist &&
                            <BsPencil onClick={handleShow}
                                style={{ fontSize: "20px", cursor: 'pointer', color: 'blue' }}
                            />
                        }
                        {userdetail && status == "admin" &&
                            datalist && <AiOutlineDelete onClick={() => { window.confirm('Are you sure you want to delete this Applicant?',) && deleteCard(_id) }} style={{ marginLeft: "10px", cursor: 'pointer', fontSize: "20px", color: 'red' }} />
                        }
                    </div>

                </div>
                {datalist.pictureU &&
                    <div className='row'>
                        <div className='d-flex justify-content-center'>

                            <img src={datalist && datalist.pictureU} alt="profile" className='img-fluid'
                                width='200px' height='200px' style={{ borderRadius: '50%' }}
                            />
                        </div>
                    </div>
                }
                {datalist ?
                    <div className='mb-5 mt-2' >
                        <div className='row ' style={{ color: "#003396" }}>
                            <div className='d-flex justify-content-evenly mb-3'>
                                <h5>Session : {datalist.session}</h5>
                                <h5>Form Status : {datalist.formStatus.toUpperCase()}</h5>
                                {/* <div className='justify-content-center '>
                                </div> */}
                            </div>
                            <div className='col-lg-6   ' style={{ border: '3px solid #003396' }}>
                                <div className='text-center'>

                                    <h4 className='fw-bolder mb-3' >Personal Information</h4>
                                </div>
                                <div className='row ' >
                                    <div className='col-6' >

                                        <h5>Name </h5>
                                        <h5>Father Name </h5>
                                        <h5>CNIC </h5>
                                        <h5>Father CNIC </h5>
                                        <h5>Date of Birth </h5>
                                        <h5>Contact No </h5>
                                        <h5>fatherOccuption </h5>
                                        <h5>gender</h5>

                                        <h5>Religion </h5>
                                        <h5>Nationality</h5>
                                        <h5>postalAddress  </h5>
                                        <h5>Permanent Address </h5>

                                    </div>
                                    <div className='col-6'>
                                        <h5> {datalist.name}</h5>
                                        <h5> {datalist.fatherName}</h5>
                                        <h5> {datalist.cnic}</h5>
                                        <h5> {datalist.fatherCnic}</h5>
                                        <h5> {datalist.Dob}</h5>
                                        <h5>{datalist.contactno}</h5>
                                        <h5>{datalist.fatherOccuption}</h5>
                                        <h5>{datalist.gender}</h5>
                                        <h5> {datalist.religion}</h5>
                                        <h5>{datalist.nationality}</h5>
                                        <h5> {datalist.postalAddress}</h5>
                                        <h5> {datalist.permanentAddress}</h5>

                                    </div>
                                </div>
                            </div>


                            <div className='col-lg-6  ' style={{ border: '3px solid #003396' }}>
                                <div className='row' >
                                    <h5 className='text-center fw-bold mb-3'>----Metriculation Record----</h5>
                                    <div className='col-6' >
                                        <h5>Board Name</h5>
                                        <h5>Passing year  </h5>
                                        <h5>Roll no </h5>
                                        <h5>Obtain Marks </h5>
                                        <h5>Total Marks</h5>
                                        <h5>Subject</h5>
                                    </div>
                                    <div className='col-6' >

                                        <h5> {datalist.matricBoardname}</h5>
                                        <h5> {datalist.matricpassingyear}</h5>
                                        <h5> {datalist.matricRollno}</h5>
                                        <h5> {datalist.matricObtmarks}</h5>
                                        <h5> {datalist.matricTmarks}</h5>
                                        <h5>{datalist.matricSubject}</h5>
                                    </div>
                                    <hr />
                                    <h5 className='text-center fw-bold mb-3'>----Intermediate Record----</h5>
                                    <div className='col-6' >

                                        <h5>Board Name   </h5>
                                        <h5>Passing year </h5>
                                        <h5>Roll no  </h5>
                                        <h5>Obtain Marks </h5>
                                        <h5>Total Marks </h5>
                                        <h5>Subject </h5>
                                    </div>


                                    <div className='col-6' >

                                        <h5>{datalist.interBoardname}</h5>
                                        <h5> {datalist.interpassingyear}</h5>
                                        <h5>{datalist.interRollno}</h5>
                                        <h5> {datalist.interObtmarks}</h5>
                                        <h5> {datalist.interTmarks}</h5>
                                        <h5> {datalist.interSubject}</h5>
                                    </div>




                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row' style={{ color: "#003396" }}>
                            <div className='col-lg-6' style={{ border: '3px solid #003396' }}>
                                <h5>Hafiz Quran  : {datalist.hafizQuran}</h5>
                                <h5>Hostal Need  : {datalist.hostalneed}</h5>
                                <h5>Transport Need : {datalist.transportneed}</h5>
                                {renderListdatashow() &&
                                    <h5>Hifaz Marks : {datalist.hifazNo == "true" ? "20" : "0"}</h5>
                                }
                            </div>

                            <div className='col-lg-6' style={{ border: '3px solid #003396' }}>
                                <h5 className='text-center fw-bold mb-3'>----Applied departments----</h5>
                                <h5> {datalist.deptts1.toUpperCase()} , {datalist.deptts2.toUpperCase()} , {datalist.deptts3.toUpperCase()}
                                    {/* , {datalist.deptts5.toUpperCase()}  */}
                                    , {datalist.deptts4.toUpperCase()}
                                </h5>

                            </div>
                        </div>

                        <hr />
                        <hr />


                    </div> :
                    <div className='d-flex justify-content-center'>

                        <ThreeDots color="#003396" height={80} width={80} />
                    </div>
                }
                <div className='row mb-5'>
                    <button className='btn btn-primary' onClick={() => { setPicshowhandle(!picshowhandle) }}>
                        {picshowhandle ? "Hide Documents" : "Show Documents"

                        }
                    </button>
                </div>
                {picshowhandle &&
                    (datalist.PicUrls ?
                        <div className='row '>

                            {

                                datalist.PicUrls && datalist.PicUrls.length > 0 && datalist.PicUrls.map((item, index) => {
                                    return (


                                        <div key={index} className="col-sm-4 col-lg-4 col-md-4 pb-1 d-flex justify-content-center " >
                                            <div className="imageourprojectfulldiv">
                                                <img src={item} className="img img-fluid overlay_container" alt={'Doc' + (serialNo = serialNo + 1)} style={{ width: '200px', height: '200px' }} />
                                                <div className="middle" >
                                                    <a href={item} className="text text_deco">
                                                        View
                                                    </a>
                                                </div>
                                            </div>
                                        </div>


                                    )
                                })
                            }


                        </div>
                        : 'Loading....')}




            </div>
            <div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{datalist.email}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex flex-column'>
                            <label className='mt-2'>
                                Name
                            </label>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value.toUpperCase())} />
                            <label className='mt-2'>
                                F/ Name
                            </label>
                            <input type="text" value={fatherName} onChange={(e) => setfatherName(e.target.value.toUpperCase())} />
                            <label className='mt-2'>
                                CNIC
                            </label>
                            <input type="text" value={cnic} onChange={(e) => setcnic(e.target.value)} />
                            <label className='mt-2'>
                                F / CNIC
                            </label>
                            <input type="text" value={fatherCnic} onChange={(e) => setfatherCnic(e.target.value)} />
                            <label className='mt-2'>
                                Dob
                            </label>
                            <input type="text" value={Dob} onChange={(e) => setDob(e.target.value)} />
                            <label className='mt-2'>
                                Matric Obtain Marks
                            </label>
                            <input type="text" value={matricObtmarks} onChange={(e) => setmatricObtmarks(e.target.value)} />
                            <label className='mt-2'>
                                Matric Total Marks
                            </label>
                            <input type="text" value={matricTmarks} onChange={(e) => setmatricTmarks(e.target.value)} />
                            <label className='mt-2'>
                                Inter Obtain Marks
                            </label>
                            <input type="text" value={interObtmarks} onChange={(e) => setinterObtmarks(e.target.value)} />
                            <label className='mt-2'>
                                Inter Total Marks
                            </label>
                            <input type="text" value={interTmarks} onChange={(e) => setInterTmarks(e.target.value)} />

                            {renderListupdateshow2() &&
                                <label className='mt-2'>
                                    Hafiz Quran
                                </label>}

                            {renderListupdateshow2() &&
                                <select value={hafizQuran} onChange={(e) => setHafizQuran(e.target.value)}>

                                    <option value="">Select a form type</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>}
                            {renderListupdateshow() &&
                                <label className='mt-2'>
                                    Hafiz Marks
                                </label>
                            }
                            {renderListupdateshow() &&
                                <select value={hafizQuranMarks} onChange={(e) => setHafizQuranMarks(e.target.value)}>


                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleUpdate}>
                            {spinner ? "loading.." : "Save Changes"}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default AppDetails