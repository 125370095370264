

import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { BsPencil } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdAddCircleOutline } from 'react-icons/md'
import './TotalDeptt.css'
import { API_URL } from "../../config"

const TotalDeptt = () => {

    const [datalist, setDatalist] = useState([])
    const [loader, setLoader] = useState(false)
    const [depttname, setDepttname] = useState('')
    const [depttemail, setDepttemail] = useState('')
    const [eligibel, setEligibel] = useState([])
    const [datafetchcontrol, setDatafetchcontrol] = useState(false)
    const [id, setId] = useState('')
    const [newdepttname, setNewdepttname] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [newpassword, setNewpassword] = useState('')
    const [newconformpassword, setNewconformpassword] = useState('')
    var list = datalist && datalist.slice()
    var newlist = datalist && list.sort((a, b) => a.depttname.localeCompare(b.depttname));
    // console.log(newlist)
    // console.log(datalist)
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setChecked([])
        setShow(false)
    }
    const [mshow, setMshow] = useState(false);


    //  This is for multiple check box for creating department  //
    const [checked, setChecked] = useState([]);
    const checkList = ["FSc Pre-Med", "FSc Pre-Eng", "FCS", "FSc(Health,Den etc)", "FA", "D-com", "DAE"];
    // console.log(checked, 'this is checklist')
    // console.log(checked.length)

    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };

    const checkedItems = checked.length
        ? checked.reduce((total, item) => {
            return total + ", " + item;
        })
        : "";
    var isChecked = (item) =>
        checked.includes(item) ? "checked-item" : "not-checked-item";



    const handleMClose = () => {

        setMshow(false)
        setNewdepttname('')
        setNewEmail('')
        setNewpassword('')
        setNewconformpassword('')
        setChecked([])
    };
    const handleMShow = () => setMshow(true);
    const handleCreatedeptt = () => {
        if (newdepttname === '' || newEmail === '' || newpassword === '' || checked.length === 0) {
            return toast.error("Please fill all the fields")
        }
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(newEmail)) {
            toast.error("Invalid email !");
            return
        }

        if (newpassword.length < 6) {
            toast.error("Password must be atleast 6 characters long")
            return
        }
        if (newpassword !== newconformpassword) {
            toast.error("Password and Confirm Password must be same")
            return
        }
        // console.log(newdepttname, newEmail, newpassword);
        setLoader(true)
        fetch(`${API_URL}/depttsignup`, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify({
                newdepttname, newEmail, newpassword,
                allowsubject: checked
            })
        })
            .then(response => {
                // console.log(response.status)
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 500) {
                    response.json()
                        .then(data =>
                            toast.error(data.message)

                        );



                    return
                }
                throw new Error(
                    setLoader(false),
                    toast.error("Server down")


                )
            })
            .then(data => {
                setLoader(false)
                toast.success(data.message)
                setMshow(false)
                setNewdepttname('')
                setNewEmail('')
                setNewpassword('')
                setNewconformpassword('')
                setChecked([])
                setDatafetchcontrol(!datafetchcontrol)
            },
            ).catch(err => {
                setLoader(false)
                // toast.error("Server down")
            })
    }
    const handleShow = (values) => {

        setShow(true)
        setDepttname(values.depttname)
        setDepttemail(values.email)
        setEligibel(values.allowsubject)
        setId(values._id)

    }
    const handleupdatedeptt = () => {
        // console.log(checked, checked.length, 'checklist')
        if (depttname === '' || depttemail === '' || checked.length === 0) {
            return toast.error("Please fill all the fields")
        }
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(depttemail)) {
            toast.error("Invalid email !");
            return
        }
        setLoader(true)
        fetch(`${API_URL}/admindepttupdate/${id}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify({
                depttname,
                depttemail,
                allowsubject: checked
            })
        })
            .then(response => {
                // console.log(response)
                if (response.ok) {
                    return response.json();
                }
                // console.log(response)
                throw new Error(
                    toast.error(response),
                    setLoader(false),


                )
            })
            .then(data => {
                // console.log(data)
                setLoader(false)
                toast.success(data.message)
                setShow(false)
                setChecked([])
                setDatafetchcontrol(!datafetchcontrol)
            },
            ).catch(err => {
                setLoader(false)
                // console.log(err)
                // toast.error(err.message)
                toast.error("Server down")
            })
    }
    const handleDeletedeptt = (id) => {
        fetch(`${API_URL}/admindeletedeptt/${id}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            toast.success("Deleted Successfully")
            setDatafetchcontrol(!datafetchcontrol)
        }).catch(err => {

            toast.error("Server down")
        })
    }

    var serialNo = 0;
    useEffect(() => {
        setDatafetchcontrol(false)
        setLoader(true)
        fetch(`${API_URL}/adminregisterdeptt`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },


        })
            .then(response => {
                // console.log(response)
                if (response.ok) {
                    return response.json();
                }
                // console.log(response.data.message)
                throw new Error(
                    setLoader(false),
                    // console.log(response),
                    toast.error("Server down")

                )
            })
            .then(data => {
                setDatalist(data.resp)
                setLoader(false)
                // console.log(data)
            },
            ).catch(err => {
                setLoader(false)
                // console.log(err)
                toast.error(err)
            })


    }, [datafetchcontrol])

    return (
        <div className='container'>
            <ToastContainer />
            <div className='' style={{ marginTop: '100px', marginBottom: '200px' }}>
                <div className='text-center'>
                    <h1 className='mb-5' style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>Departments List</h1>
                </div>
                <div className='row'>
                    <div className='d-flex justify-content-end'>
                        {loader ? null : <MdAddCircleOutline style={{ fontSize: '50px', color: '#003396', cursor: 'pointer' }} onClick={() => { handleMShow() }}
                        />}
                    </div>
                </div>
                {loader ? <div className='' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ThreeDots color="#003396" height={80} width={80} />

                </div> :



                    <Table responsive variant="ligth"
                        id='test-table-xls'
                    >
                        <thead>
                            <tr>
                                <th>S/No</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Eligibelity</th>
                                <th>Update/Delete</th>

                            </tr>
                        </thead>
                        <tbody>
                            {newlist ? newlist.map((elem) =>
                                <tr key={elem._id}>
                                    <td>{serialNo = serialNo + 1}</td>
                                    <td>{elem.depttname}</td>
                                    <td>{elem.email}</td>
                                    <td className='d-flex'>{
                                        elem.allowsubject.map(elm => <p key={elm}>"   {elm}   " </p>)
                                    }
                                    </td>
                                    <td >
                                        <BsPencil onClick={() => handleShow(elem)} className='pencilicondepttadmin'
                                            style={{ fontSize: "20px", }} />
                                        <AiOutlineDelete className='deleteicondepttadmin'
                                            onClick={() => { window.confirm('Are you sure you want to delete this department?',) && handleDeletedeptt(elem._id) }}
                                            style={{ fontSize: "20px", marginLeft: '10px', }}
                                        />
                                    </td>
                                    <td></td>
                                    {/* <td>
                                        <Link to={`/appdetail/${elem._id}`}>
                                            Detail
                                        </Link></td> */}
                                </tr>
                            ) : null}
                        </tbody>
                    </Table>}
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex   flex-column'>
                        <label className='mt-2'>
                            Department Name
                        </label>
                        <input type="text" required value={depttname} onChange={(e) => setDepttname(e.target.value)} />
                        <label className='mt-2'>
                            Email
                        </label>
                        <input type="email" required value={depttemail} onChange={(e) => setDepttemail(e.target.value)} />

                    </div>
                    <div className='mt-3'>
                        <h5>Eligibel Subject :</h5>

                        {/* <p className=''>{eligibel}</p> */}
                        <div className='d-flex'>
                            {
                                eligibel.map(elm => <p key={elm}>"   {elm}   " </p>)
                            }

                        </div>


                    </div>
                    <div className="checkList mt-3">
                        <div className="title text-center fw-bold">Eligibility List:</div>
                        <div className="list-container ">
                            {checkList.map((item, index) => (
                                <div key={index}>
                                    <input value={item} type="checkbox" onChange={handleCheck} />
                                    <span className={isChecked(item)}>{item}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h5>Selected :</h5>
                        {checkedItems}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleupdatedeptt}>
                        {loader ? "Loading..." : "Save Changes"}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={mshow} onHide={handleMClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column'>
                        <label className='mt-2'>
                            Department Name
                        </label>
                        <input type="text" required value={newdepttname} onChange={(e) => setNewdepttname(e.target.value)} />
                        <label className='mt-2'>
                            Email
                        </label>
                        <input type="email" required value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                        <label className='mt-2'>
                            Password
                        </label>
                        <input type="password" required value={newpassword} onChange={(e) => setNewpassword(e.target.value)} />
                        <label className='mt-2'>
                            confirm Password
                        </label>
                        <input type="password" required value={newconformpassword} onChange={(e) => setNewconformpassword(e.target.value)} />

                    </div>
                    <div className="checkList mt-3">
                        <div className="title text-center fw-bold">Eligibility List:</div>
                        <div className="list-container ">
                            {checkList.map((item, index) => (
                                <div key={index}>
                                    <input value={item} type="checkbox" onChange={handleCheck} />
                                    <span className={isChecked(item)}>{item}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>
                        <h5>Selected :</h5>
                        {checkedItems}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleMClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreatedeptt}>
                        {loader ? "Loading..." : "Create Department"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TotalDeptt