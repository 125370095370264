import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    activeUser: null,
    pictureU: null,
    PicUrls: []
}

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setActiveUser: (state, action) => {
            state.activeUser = action.payload

        },
        setpictureU: (state, action) => {
            state.uplaoduserPic = action.payload
        },
        setPicUrls: (state, action) => {
            state.uplaoduserPic = action.payload
        },

        setLogout: (state) => {
            state.activeUser = null

        }


    }
});

export const { setActiveUser, setLogout, setActiveUserUpdate, setPicUrls, setpictureU } = UserSlice.actions

export default UserSlice.reducer