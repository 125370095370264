import React from 'react'
import { Routes, Route, Link, Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ isAllowed, redirectPath = '/applogin', }) => {


    if (!isAllowed) {
        return (

            <Navigate to={redirectPath} replace />
        );
    }
    return (


        <Outlet />

    );
}

export default ProtectedRoute