import React from 'react'
import "./Aboutus.css"
const FAQ = () => {
  return (
    <div >
      <div className='container-fluid allaboutusbanerfulldiv' >
        <div className='allaboutbanertext'>
          <h1>FREQUENTLY ASK QUESTIONS</h1>
        </div>
      </div>
      <div className='container'>
        <div className='directorheadingaboutus mt-5'>
          <h4>
            FAQS (FREQUENTLY ASK QUESTIONS)
          </h4>

        </div>
        <div className=' mt-4'>
          <p>
            The University of Swabi offers admission in BS, MSc, MS, MPhil, and PhD programmes.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Please refer these answers as a first resource for queries about applying to the UOS-Admissions. If you do not find your answers in these information, please contact UOS-Admissions at the following link: https://admissionsuoswabi.com/ or email at admissions@uoswabi.edu.pk or Admission Office Contact Numbers at:<strong> +92-938490500.</strong>
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q1. Recognition of University of Swabi
          </p>
          <p className='mt-3'>
            University of Swabi was established in 2012 with the mission to advance knowledge and learning through quality research and education for the whole nation. Currently, the university comprises of two campuses, old campus and main campus having a combined area of 188 acres nears M1 Motorway.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q2. Academic Programmes offered by the university
          </p>
          <p className='mt-3'>
            There are 27 Academic Departments at University of Swabi. Currently more than 4000 students are enrolled in BS, Masters and MS/MPhil degree programs. There are one Boy’s, one Girl's and one Staff Hostel at the campus. The Central Library consists of more than 20,000 books of different disciplines
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            3: Procedure to apply
          </p>
          <p>
            Only online apply is acceptable.
          </p>
          <p className='mt-3'>
            To create an online application account through University Online Admission, you are required to have a valid email address. Please use your account (Any Email) to create your online application account and validate your account with email verification. Then access to your UoS-Admissions account by filling the appropriate information on the required format.  (UoS-Admissions System will guide you steps by step)

            It is advised to create only One Account for the online application submission.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q4. Eligibility criteria of each programmes.
          </p>
          <li>
            For all BS programmes, candidates must have passed Intermediate (FSc/FA/ICS) exam or equivalent.
          </li>
          <li>
            Students who have done A-level or any other qualification from abroad are required to produce equivalency certificate from relevant authority.
          </li>
          <li>
            For all MPhil/MS and MBA, candidate must have passed relevant BS level degree with at-least a CGPA 2.5 or above or 60% and GAT (General)<strong>OR</strong>  GRE (General) test with at-least 50% marks.
          </li>
          <li>
            For all PhD programmes, candidate must have passed relevant MS/MPhil (18 years of education) with at-least CGPA 3.0 and at least obtained 60% marks in GAT subject or scored 60 and UGAT (University own test), the score must be 70% or more.
          </li>
        </div>

        <div>
          <li>
            To manage Publications of University Prospectus under Graduate and Postgraduate programs.
          </li>
          <li>
            To finalized admission schedules and give them wide publicity.
          </li>
          <li>To collect and process application forms received for admission various programs.</li>
          <li>To coordinate with concerned departments in matters relating to admissions.</li>
          <li>To ensure compliance with admission standard policies and regulation.</li>
        </div>

        <div className=' mt-4'>
          <p>
            Q5. Where can I give test?
          </p>
          <p className='mt-3'>
            The university is arranging its own test to facilities candidates who wish to get admission into the PhD or MS/MPhil program.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q6. Fee structure of each academic programme – tuition fee and others
          </p>
          <p className='mt-3'>
            Keeping in view the socio-economic situation of the area, fee of all programmes is kept very low. Details can be found on at...
            <a href='https://www.uoswabi.edu.pk/cmscategory/index/3'>click here for details</a>
          </p>
        </div>

        <div className=' mt-4'>
          <p>
            Q7. Financial aid and Scholarships
          </p>
          <p className='mt-3'>
            A number of lucrative scholarships are available at HU through the HEC, other government organizations, the list of all such scholarship is already provided in the admission advertisement. The list can also be viewed on..
            <a href=' https://www.uoswabi.edu.pk/'>click here</a>
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Payment
          </p>
          <p className='mt-3'>
            Tuitions fee is paid semester wise.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q8. Details of deadlines and submissions
          </p>
          <p className='mt-3'>
            Deadlines for various activities are announced through website and admission advertisements. Read and follow these deadlines.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q9. Hostel facilities and fee structure
          </p>
          <p className='mt-3'>
            Hostel facilities on affordable fee are available for both male and female students. Interested students are required to apply for hostel after using the online admission system.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q10. Transport and fee structure
          </p>
          <p className='mt-3'>
            The university provides pick and drop facilities from various points around the university with highly subsidized rates. Pick and drop points are identified and finalized after the consultation with students availing this facilities.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q11. Facilities in the campus
          </p>
          <p className='mt-3'>
            The campus is spread over on more than 1400kanals land surrounded by beautiful scenic views of the mountains.
            <br />
            Campus provides outstanding facilities for curricula, co and extra curricula activities. Sports facilities of international standards are available for students with no additional cost. In order to enable students to play games, the university is planning to install lights with few sports facilities from this summer onward.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q11. Facilities in the campusQ12. Selection criteria, what are the various steps that decides the selection
          </p>
          <p className='mt-3'>
            The university is open to all and offers admission strictly on merit basis who fulfils the admission criteria.  However, there are some reserved seat for various areas of the country such as FATA, special persons, (on flooting basis), etc.
            <br />
            Steps for the admission:
          </p>
          <li>
            Submit complete online application
          </li>
          <li>
            After the closing date merit list of the successful candidates will be displayed on website. (Note: Mater and PhD students will also be required to appear in the interview)          </li>

          <li>
            Deposit tuition fee and other fee on deadline.
          </li>

        </div>

        <div className=' mt-4'>
          <p>
            Q13. Research facilities
          </p>
          <p className='mt-3'>
            UoS provides state of art research facilities for both undergrad (BS) and graduate (MS & PhD) level students. Interested students are required to visit official website or campus and meet various faculty members related to their degree of interest.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q14. Assistantship for Students
          </p>
          <p className='mt-3'>
            Yes, limited seats are available and award to Master and PhD students only in specific area where applicable.
          </p>
        </div>
        <div className=' mt-4'>
          <p>
            Q15. PhD Approved supervisor
          </p>
          <p className='mt-3'>
            Yes, a number of HEC PhD Approved supervisors are available with the university.
          </p>
        </div>



      </div>
    </div>
  )
}

export default FAQ