import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../config'
export const DepttsLogin = createAsyncThunk(
    'DepttLogin', async (data) => {
        // console.log("this is data", data)
        const { email, password } = data
        // console.log('this is destructure data', email, password)

        try {
            const res = await fetch(`${API_URL}/depttsignin`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email, password
                })
            })
            const data = await res.json()


            // console.log("response", data)
            if (res.status === 200) {
                localStorage.setItem('token', data.token)
                localStorage.setItem('userdetail', JSON.stringify(data.depttUser))

            }
            return data

        } catch (error) {
            // toast.error(error)
            // console.log(error)

        }

    }
)