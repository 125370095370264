import React from 'react'
import { Link } from 'react-router-dom'

const Errorpage = () => {
    return (
        <div className='text-center' style={{ marginTop: '200px' }}>
            <h2 style={{ color: 'red' }}>Sorry! Page not found 404</h2>
            <Link to='/'>Go to Home page</Link>
        </div>
    )
}

export default Errorpage