import React from 'react'
import bg from '../../assets/bg2.jpeg'
import './Criteria.css'
export default function Criteria() {
    return (
        <div >
            <div style={{ backgroundImage: `url(${bg})`, height: "70vh" }}></div>
            <div className='container text-center'>
                <h3 className='mt-5'>ELIGIBILITY CRITERIA OF BS AND POSTGRADUATE</h3>
            </div>
            <div className='container-fluid'>
                <div className='px-4 mx-5 mt-5 pt-4'>
                    <h4 className='text-left pb-3'>Department of Agriculture</h4>
                    <div className='row border'>
                        <div className='col-sm-3  bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9  bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BSc (Honors) Agriculture in various disciplines</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.Sc (Pre-Medical/ Pre-Engineering) or equivalent with at least 2nddivision (45% marks) or Associate Degree in Agriculture with a CGPA of 2.00 out of 4.00 for admission to 5th semester of B.Sc (Honors) Agriculture</p>
                        </div>
                    </div>

                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MSc (Honors) Agriculture in various disciplines</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>Relevant master’s degree/ equivalent degreeor 4-years educational degree awarded after intermediate (with minimum credit hours of 124) or with a minimum CGPA of 2.50/4.00 in semester system or 2nddivision in annual system in specialized discipline and;
                                <br />
                                Qualifying Departmental Screening Test with at least 60% marks</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>PhD in various disciplines</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>Relevant MS/ MPhil/ M.Sc (Honors)/ equivalent degree or degree awarded after 18-years education with a CGPA of 3.00/4.00 in semester system or 1stdivision in annual system in specialized discipline and;
                                <br />
                                Qualifying Departmental Screening Test with at least 70% marks</p>
                        </div>
                    </div>
                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Deptt of Biotechnology</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border-left bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Biotechnology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F. Sc Pre-Medical or equivalent with at least 2nd division (45% marks) or Associate Degree in Science (relevant subject) with a CGPA of 2.00 out of 4.00for admission to 5th semester</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Deptt of Botany</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border-left bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Botany</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.Sc (Pre-Medical) or equivalent with at least 2nd Division (45% Marks)or Associate Degree in Botany with a CGPA of 2.00 out of 4.00 for admission to 5th Semester</p>
                        </div>
                    </div>
                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'> Deptt of Chemistry</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.Sc (Pre-Medical/Pre-Engineering) or Equivalent with at least 2nd division</p>
                        </div>
                    </div>

                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MSc</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>B.Sc (with Chemistry) with at least 2nd division</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MPhil</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>· &nbsp; Sixteen years of relevant schooling or 4 years relevant education after HSSC/F.Sc/Grade 12 or equivalent.
                                <br />
                                ·    &nbsp;   Qualified the GAT(General) conducted by the National Testing Service with a minimum of 50% cumulative score OR 60% score in University/ departmental test at the time of admission in M.Phil program.
                                <br />
                                ·     &nbsp;  The candidates should have:
                                <br />
                                (a) &nbsp;  Obtained 2nd division in annual system of examination and 2.50 CGPA in  BS Chemistry (4-years), M.Sc in Chemistry in semester system examination.
                                <br />
                                (b) &nbsp;  No 3rd division in the entire academic career.</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>PhD</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>a    MS/MPhil Degree in Chemistry with a minimum of CGPA 3.00 in semester system of at least 30 credit hours or 1st division in annual system.

                                b     No 3rd division in the entire academic career.

                                c     Qualified the GAT (Subject) conducted by the National Testing Service with a minimum of 60% cumulative score OR 70% score in University/ departmental test at the time of admission in Ph.D program. </p>
                        </div>
                    </div>
                </div>


                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Deptt of Computr Science</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Computer Science</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>§  FSc Pre-Engineering

                                §  FSc Pre-Medical

                                §  ICS- Intermediate in Computer Science

                                §  FSc General Science (With Computer and Mathematics)

                                §  Diploma (03 years) in Associate Engineering with subject of Mathematics and equivalence from IBCC

                                §  Admission in 1st semester will be granted in BS program. However, students shall have the option to exit after completion of 4th semesters with an associate degree.</p>
                        </div>
                    </div>

                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MS in Computer Science</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>§  A degree earned after sixteen years of education in computing or a related Discipline, with a minimum CGPA 2.5 (out of 4.0 in semester system) or 2nd division in annual system

                                §  Candidate must fulfill GAT/NTS requirements or qualify screening test conducted by the University by securing the required score as per the University guidelines</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Economics</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Economics</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>§  Intermediate with at least 45% Marks from a recognized institution or A-Levels (22 Points) with Equivalency Certificate from Inter Board Committee of Chairman (IBCC) Islamabad or an equivalent certificate from a recognized institution are eligible to apply.

                                §  Candidates are required to pass entry test/interview (if any) conducted by the University/College.</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of English</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in English</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.A/F.Sc or equivalent with at least 2nd division.</p>
                        </div>
                    </div>

                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MPhil in English</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>·       Relevant Master’s degree/ Equivalent degree or 4-years education after intermediate (130 Credit hours), with a minimum CGPA 2.5 in Semester System or 2nd Division in Annual System.

                                ·       Candidate must pass Screening Test conducted by the Department by securing at least 60% marks</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Environmental Sciences</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Environmental Sciences</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>·       FSc or Equivalent

                                ·       Intermediate (Statistics, Economics, Mathematics, Commerce and Computer Science)

                                ·       Diploma holder candidates (MLT, ADE etc.) must provide an equivalent certificate from IBCC</p>
                        </div>
                    </div>

                </div>


                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of   Geology</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Geology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>The department accepts students in its BS-Geology program after their Intermediate education (F.Sc. pre-engineering or pre-medical) or equivalent of 12-years of education.</p>
                        </div>
                    </div>

                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MS Geology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>MS program: BS (4-Years) or equivalent education in Geology/Earth Sciences/Relevant Subjects with minimum 55% marks/ or 2.5 CGPA is recommended for admission in 2-year MS/M.Phil in Geology.

                                Note: Candidates must have passed GAT (General) conducted by NTS or subject test conducted by University of Swabi with at least 60% marks.</p>
                        </div>
                    </div>

                </div>


                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of ISLAMIC AND ARABIC STUDIES</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Islamic Studies, Diploma in Arabic language</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>The admission and eligibility criteria for BS in Islamic Studies (04 Years/08 Semesters) Program will be FA / FSC / ShahadathulKhassa (شہادہ الخاصہ) from any Islamic Board recognized by government of Pakistan or equivalent certificate from any government recognized institute with 45 % of marks obtained.</p>
                        </div>
                    </div>

                </div>
                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>DEPARTMENT OF JOURNALISM & MASS COMMUNICATION</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Journalism and Mass communication</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>·       Intermediate (FA, FSc) with at least 45% marks from a recognized institution or A-Levels (22 Points) with Equivalency Certificate from Inter Board Committee of Chairmen (IBCC) Islamabad or an equivalent certificate from a recognized institution are eligible to apply.

                                ·       Candidates are required to qualify entry test/ interview conducted by the University of Swabi.</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Law</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>LLB 5 Years</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.A/ F.Scor  Equivalent with at least 2nd division and LAT Test</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Mathematics</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Mathematics /AD in Mathematics</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>·        F.A/F.Sc with Mathematics, at least 45% Marks.

                                ·        Any other examination of a Foreign University / Institution / Examining Body, equivalent to Intermediate with Mathematics. Equivalence and percentage of marks will be determined by IBCC.

                                ·        Diploma of Associate Engineering Examination, securing at least 45% marks in aggregate</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MS in Mathematics</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>·       The applicants must have completed 4-year BS (Mathematics) with CGPA 2.5 out of 4.0, from HEC recognized University/ Institute;

                                Q

                                ·       The applicants must have completed MSc in Mathematics with at least 2nd Division/ 2.5 CGPA out of 4 / or equivalent grade, from HEC recognized University/ Institute.</p>
                        </div>
                    </div>

                </div>


                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Management Sciences</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BBA (Hons)</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>FA/FSc. or equivalent with at least 2nd division (45% marks).</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MBA (2-Years) (Candidates having Business Background)</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>Q     Entry/ Admission Requirements

                                a)     BBA (HONS)/M.Com/BS in Business Administration related subjects or Equivalent 16 years of business education with minimum of 2.5 (out of 4.0 CGPA) in semester system and 45% marks in annual system.

                                b)    Candidates must pass screening test conducted by the department by securing at least 60% marks or 50% marks in GAT (General).</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MS (Management Sciences)</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>a)     BBA (HONS)/M.Com/BS in Business Administration related subjects or Equivalent 16 years of business education with minimum of 2.5(out of 4.0 CGPA)in semester system and 45% marks in annual system or as per HEC criteria.

                                b)    Candidates must pass screening test conducted by the department by securing at least 60% marks or 50% marks in GAT (General) or as per HEC criteria.</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>Doctor of Philosophy (PhD)</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>a)     Candidates having relevant MS/M.Phil./Equivalent degree or 18 years of education with a CGPA of 3.0 out of 4.0 in the semester system or 1st division in annual system can apply or as per HEC criteria.

                                b)    Candidates must pass GAT (Subject) by securing 60% marks or screening test conducted by the department by securing at least 70% marks or as per HEC criteria.</p>
                        </div>
                    </div>

                </div>



                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Microbiology</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Microbiology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.Sc (Pre-Medical) or Equivalent or Diploma in MLT (having equivalency from IBCC) with at least 2nd Division (45%) m arks</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MPhil in Microbiology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>MPhil: Relevant Master’s degree/ equivalent degree or 04 years education after intermediate (130 credit hours), with a minimum CGPA of 2.5 in semester system or 2nd division in annual system</p>
                        </div>
                    </div>

                </div>








                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Pharmacy</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>Pharm-D</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>o   F.Sc (Pre Medical) or Equivalent with 1st Division (Priority 1),

                                o   B.Sc (Biological Sciences) with 1st division (limited seats, priority 2)</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>M.Phil</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>
                                ·       Relevant Masters/equivalent degree (at least sixteen years of relevant schooling or at least 4 years relevant education after HSSC/F.Sc/Grade 12 or equivalent).

                                ·       Qualified the GAT (General) conducted by the National Testing Service with minimum of 50% cumulative score OR 60% score in University/ departmental test at the time of admission in M.Phil program.

                                ·       The candidates should have:

                                ·       Obtained at least 2nd division in annual system of examination and 2.50 (on the scale of 4) CGPA in BS (4-years) in semester system examination, from an HEC recognized university/institute.

                                ·       No 3rd division in the entire academic career.</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>PhD</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>·       MS/MPhil Degree (at least 18 years of education) in the relevant subject with a minimum CGPA of 3.00 (on the scale of 4) in semester system of at least 30 credit hours or 1st division in annual system, from an HEC recognized university/institute.

                                ·       No 3rd division in the entire academic career.

                                ·       The candidates should have qualified the GAT (Subject) conducted by the National Testing Service with a minimum of 60% cumulative score (OR) 70% score in University/ departmental test at the time of admission in PhD program. </p>
                        </div>
                    </div>

                </div>


                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Pharmacy</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>Pharm-D</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>o   F.Sc (Pre Medical) or Equivalent with 1st Division (Priority 1),

                                o   B.Sc (Biological Sciences) with 1st division (limited seats, priority 2)</p>
                        </div>
                    </div>

                </div>


                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Physics</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Physics</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.Sc (Pre-Engineering/Computer Science /Pre-Medical) or Three years Diploma of Associate Engineering (DAE) from Technical Board with at least 45% marks. For the DAE students provision of F.Sc equivalence certificate from IBCC is mandatory or Associate Degree in Science (Physics) with CGPA 2.5 out of 4.00 for admission in 5th Semester.</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Political Science</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS Political Science</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>
                                Intermediate with at least 45% Marks from a recognized institution Or A level(22 points) with equivalency certificate from Inter Board Committee of Chairman (IBCC), Islamabad or an equivalence certificate from a recognized institution are eligible to apply.</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Psychology</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Psychology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>
                                FA/Fsc with 45% marks</p>
                        </div>
                    </div>

                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of PCS</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS PCS</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>
                                Intermediate with at least 45% Marks from a recognized institution or A level (22 points) with equivalency certificate from Inter Board Committee of Chairman (IBCC), Islamabad or an equivalence certificate from a recognized institution are eligible to apply.</p>
                        </div>
                    </div>
                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Sociology</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Sociology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p> FA/Fsc with 45% marks</p>
                        </div>
                    </div>
                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Tourism & Hotel Management</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Tourism & Hotel Management</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.A/F.Sc or equivalent with at least 2nd division</p>
                        </div>
                    </div>
                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Dept. of Urdu</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS in Urdu</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.A/F.Sc or equivalent with at least  2nd division or Associate Degree in Arst (Urdu) with a  CGPA of 2.00 out of 4.00 for admission to 5th semester</p>
                        </div>
                    </div>
                </div>

                <div className='px-4 mx-5 mt-5'>
                    <h4 className='text-left pb-3'>Department of Zoology</h4>
                    <div className='row border'>
                        <div className='col-sm-3 border-right-2 bg-blue bg-dark text-white p-3'>
                            <h5>Program</h5>
                        </div>
                        <div className='col-sm-9 border_right bg-dark text-white p-3'>
                            <h5>Minimum qualification</h5>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>BS Zoology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>F.Sc (Pre Medical) having at least 2nd Division (45 % marks)</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>MPhil Zoology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>·       Sixteen years of relevant schooling or 4 yearsrelevant education after HSSC/F.Sc/Grade 12 or equivalent. Qualifying the GAT-General/NTS conducted by the National Testing Service with a minimum 50% cumulative score And/OR University/departmental test at the time of admission to MPhil.  The candidates should have: Obtained 2nd division  in  annual  system  of  examination  and  2.50  CGPA  in  BS Zoology (4-years), M.Sc in Zoology semester system examination.

                                ·       No 3rd division in the entire academic career. Selection shall be made on the basis of cumulative merit to be determined from previous academic record, written test and interview</p>
                        </div>
                    </div>
                    <div className='row border'>
                        <div className='col-sm-3 border_right bg-blue  p-3'>
                            <h6>PhD Zoology</h6>
                        </div>
                        <div className='col-sm-9 border-left p-3'>
                            <p>To be eligible for admission in PhD Program, a candidate is required to have:

                                ·       MS/MPhil Degree in Zoology with a minimum of CGPA 3.00 under semester system of at least 30 credit hours or 1st division under annual system. No third division in the entire academic career. Have qualified NTS /Graduate Assessment Test (subject) or GRE (subject).Have qualified International GRE (subject) in the case of subjects specified by HEC or University own test.Selection shall be made on the basis of cumulative merit to be determined from previous academic record, written test and interview</p>
                        </div>
                    </div>
                </div>


            </div>
        </div >
    )
}
