import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../../config"
const Newpassword = () => {

    const { token } = useParams()
    //    console.log(token)

    const [password, setPassword] = useState('')
    const [cpassword, setCpassword] = useState('')
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()

    const updatePassHandle = (e) => {
        e.preventDefault()
        if (!password) {
            return toast.error("Please Enter Password")
        }
        if (!cpassword) {
            return toast.error("Please Enter Confirm Password")
        }
        if (password !== cpassword) {
            return toast.error("Password and Confirm Password does not match")
        }
        if (password.length < 6) {
            return toast.error("Password must be atleast 6 characters")
        }
        setLoader(true)
        fetch(`${API_URL}/new-password`, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                password,
                token
            })
        }).then(res => res.json())
            .then(data => {
                setLoader(false)
                // console.log(data)
                if (data.error) {
                    toast.error(data.error)
                } else {
                    // toast.success(data.message)
                    alert("Password Changed Successfully")
                    navigate('/applogin')
                }
            }).catch(err => {
                setLoader(false)
                toast.error('server down')
                // console.log(err)
            })
    }

    return (
        <div className='container'>
            <ToastContainer />
            <div className='row text-center' style={{ marginTop: '150px' }}>
                <h3 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>New Password</h3>
            </div>
            <div className='row mt-5 d-flex justify-content-center'>
                <div className='col-lg-6 col-sm-12'>
                    <div className="formdivnews">
                        <form onSubmit={updatePassHandle}>
                            <label>Password</label>
                            <input type="password" className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}

                            />
                            <label className='mt-4'>Confirm Password</label>
                            <input type="password" className="form-control"
                                value={cpassword}
                                onChange={(e) => setCpassword(e.target.value)}

                            />
                            {loader ? <button className="btn btn-primary float-end" disabled>Loading...</button> :
                                <button className="btn btn-primary float-end" type='submit'>Submit</button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newpassword