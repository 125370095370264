import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import AdminLogin from './components/adminDepttlogin/AdminLogin'
import DepttLogin from './components/adminDepttlogin/DepttLogin'
import AdminDepttloginpath from './components/adminDepttloginpath/AdminDepttloginpath'
import AdminHome from './components/adminHome/AdminHome'
import CurrApplist from './components/adminHome/CurrApplist'
import Deletedapplog from './components/adminHome/Deletedapplog'
import Messages from './components/adminHome/Messages'
import NewsAdd from './components/adminHome/NewsAdd'
import RegisterApplist from './components/adminHome/RegisterApplist'
import TotalApp from './components/adminHome/TotalApp'
import TotalDeptt from './components/adminHome/TotalDeptt'
import AdmissionDorN from './components/admissionForm/AdmissionDorN'
import AdmissionForm from './components/admissionForm/AdmissionForm'
import AppDetails from './components/appdetials/AppDetails'
import Deptthome from './components/depttHome/Deptthome'
import DepttUpdatepass from './components/depttHome/DepttUpdatepass'
import Errorpage from './components/errorpage/Errorpage'
import Aboutus from './components/home/Aboutus'
import AppHome from './components/home/AppHome'
import BsProgram from './components/home/BsProgram'
import ContactUs from './components/home/ContactUs'
import Criteria from './components/home/Criteria'
import FAQ from './components/home/FAQ'
import MsProgram from './components/home/MsProgram'
import Scholarship from './components/home/Scholarship'
import Unidocumentation from './components/home/Unidocumentation'
import LoginApp from './components/loginApp/LoginApp'
import Newpassword from './components/loginApp/Newpassword'
import ResetPassword from './components/loginApp/ResetPassword'
import SignupApp3 from './components/signupApp/SignupApp3'
import ProtectedRoute from './ProtectedRoute'
import { setActiveUser } from './redux/features/UserSlice'

const RouterClass = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // var currentUser = useSelector(state => state.user.activeUser)
    var currentUser = JSON.parse(localStorage.getItem("userdetail"))
    // console.log(!!currentUser)
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("userdetail"))

        user && dispatch(setActiveUser(user))

        // else {
        //     if (location.pathname.startsWith('/reset')) {
        //         if (location.pathname === '/reset') {
        //             navigate('/reset')
        //         } else {
        //             <NewPassword />
        //         }
        //     } else {

        //         navigate('/login')
        //     }
        // }

    }, [RouterClass])
    // console.log(!!currentUser && currentUser.status.includes('applicant'))

    return (
        <div>
            <Routes>
                <Route path="/" exact element={<AppHome />} />
                <Route path="/applogin" element={<LoginApp />} />
                <Route path="/signup" element={<SignupApp3 />} />
                <Route path="/aboutus" element={<Aboutus />} />
                <Route path="/bsprograms" element={<BsProgram />} />
                <Route path="/msprograms" element={< MsProgram />} />
                <Route path="/frequentaskquestion" element={< FAQ />} />
                <Route path="/scholarships" element={< Scholarship />} />
                <Route path="/criteria" element={< Criteria />} />
                <Route path="/admindepttloginpath" element={<AdminDepttloginpath />} />
                <Route path="/adminlogin$$$*$$$" exact element={<AdminLogin />} />
                <Route path="/depttlogin$$$*$$$" exact element={<DepttLogin />} />
                <Route path="/documentation" element={<Unidocumentation />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/reset-pass" element={<ResetPassword />} />
                <Route path="/reset-pass/:token" element={<Newpassword />} />
                <Route path="*" exact element={<Errorpage />} />
                {/* <Route path="/appdetail/:_id" exact element={<AppDetails />} /> */}


                <Route element={<ProtectedRoute
                    redirectPath="/applogin"
                    isAllowed={
                        !!currentUser && currentUser.status.includes('applicant')
                    }
                />}>
                    <Route path="/admform" element={<AdmissionDorN />} />
                    {/* <Route path="/admform" element={<AdmissionForm />} /> */}
                </Route>
                <Route element={<ProtectedRoute
                    redirectPath="/"
                    isAllowed={
                        !!currentUser && (currentUser.status.includes('department') || currentUser.status.includes('admin'))
                    }
                />}>
                    <Route path="/appdetail/:_id" exact element={<AppDetails />} />
                </Route>
                <Route element={<ProtectedRoute
                    redirectPath="/"
                    isAllowed={
                        !!currentUser && currentUser.status.includes('department')
                    }
                />}>
                    <Route path="/deptthome" exact element={<Deptthome />} />
                    <Route path="/depttupdatepassword" element={<DepttUpdatepass />} />
                    {/* <Route path="/appdetail/:_id" exact element={<AppDetails />} /> */}


                </Route>
                <Route element={<ProtectedRoute
                    redirectPath="/"
                    isAllowed={
                        !!currentUser && currentUser.status.includes('admin')
                    }
                />}>

                    <Route path="/adminh" exact element={<AdminHome />} />
                    <Route path="/adminregisterapplist" element={<RegisterApplist />} />
                    {/* <Route path="/appdetail/:_id" exact element={<AppDetails />} /> */}
                    <Route path="/adminallapplist" element={<TotalApp />} />
                    <Route path="/admincurrapplist" element={<CurrApplist />} />
                    <Route path="/admindepttlist" element={<TotalDeptt />} />
                    <Route path="/newsadd" element={<NewsAdd />} />
                    <Route path="/contactmessages" element={<Messages />} />
                    <Route path="/deletedapp" element={<Deletedapplog />} />

                </Route>


            </Routes>

        </div>
    )
}

export default RouterClass