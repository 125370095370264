import React from 'react'
import "./Aboutus"
import { Table } from 'react-bootstrap'
const MsProgram = () => {
  var Sno = 0;
  const departments = [
    {
      name: "MPhil in Agriculture",
      duration: " 02 Years "
    },

    {
      name: "MPhil in Chemistry",
      duration: " 02 Years "
    },

    {
      name: "MS in Computer Science",
      duration: " 02 Years "
    },

    {
      name: "MPhil in English",
      duration: " 02 Years "
    },

    {
      name: "MPhil in Geology",
      duration: " 02 Years "
    },
    {
      name: "MPhil in Islamic & Arabic Studies",
      duration: " 02 Years "
    },

    {
      name: "MS in Management Sciences",
      duration: " 02 Years "
    },
    {
      name: "MS in Mathematics",
      duration: " 02 Years "
    },
    {
      name: "MS in Microbiology",
      duration: " 02 Years "
    },

    {
      name: "MPhil in Pharmacy",
      duration: " 02 Years "
    },

    {
      name: "MPhil in Political Science",
      duration: " 02 Years "
    },

    {
      name: "MPhil in Urdu",
      duration: " 02 Years "
    },
    {
      name: "MPhil in Zoology",
      duration: " 02 Years "
    },
  ]
  return (
    <div>
      <div className='container-fluid allaboutusbanerfulldiv' >
        <div className='allaboutbanertext'>
          <h1>MS/MPhil PROGRAMS</h1>
        </div>
      </div>

      <div className='container'>
        <div className='directorheadingaboutus mt-5'>
          <h4 style={{ textTransform: "uppercase" }}>
            MASTER OF PHILOSOPHY OR MASTER OF SCIENCE PROGRAMME (18 YEARS OF EDUCATION)

          </h4>
        </div>
        <div className='directorparaaboutus mt-4'>
          <p>
            Master programmes such as Master of Philosophy (MPhil) and Master of Science (MS) are graduate programmes offered to students who completes 16 years of education or equivalent. A typical Master programme consists of four (04) semesters spread over two (02) years. The number of CrHr for master programme is 30 or more as per the HEC guidelines.

          </p>
        </div>
        <div className='mt-5'>

          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Programme Title</th>
                <th>Programme Duration</th>

              </tr>
            </thead>
            <tbody>
              {
                departments.map((item, index) =>
                  <tr key={index} >
                    <td >{Sno = Sno + 1}</td>
                    <td >{item.name}</td>
                    <td >{item.duration}</td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>

      </div>
    </div>
  )
}

export default MsProgram