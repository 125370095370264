import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../../config"

const NewsAdd = () => {
    const [newsdata, setNewsdata] = useState('')
    const [loader, setLoader] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!newsdata) {
            return toast.error('please write a news message')
        }
        setLoader(true)
        fetch(`${API_URL}/newsmessages`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify({
                newsdata
            })

        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setLoader(false),
                    toast.error("Server down")

                )
            })
            .then(data => {
                // console.log(data)
                toast.success(data.message)
                setNewsdata('')
                setLoader(false)
            },
            );
    }
    return (
        <div className='container'>
            <ToastContainer />
            <div className='row text-center' style={{ marginTop: '150px' }}>
                <h3 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>News Add</h3>
            </div>
            <div className='row mt-5 d-flex justify-content-center'>
                <div className='col-lg-6 col-sm-12'>
                    <div className="formdivnews">
                        <form onSubmit={handleSubmit}>
                            <input type="text" placeholder="write news..." className="form-control"
                                value={newsdata}
                                onChange={(e) => setNewsdata(e.target.value)}

                            />
                            {loader ? <button className="btn btn-primary float-end" disabled>Loading...</button> :
                                <button className="btn btn-primary float-end" type='submit'>Submit</button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsAdd