import React, { useEffect } from 'react';
import './SignupApp3.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form } from 'formik';
import { TextField } from './SignupApp2'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppReg } from '../../redux/services/AppUser';
import { clearState } from '../../redux/features/AppUserSlice';
import { useNavigate } from 'react-router-dom';

const validate = Yup.object({
    name: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .min(4, 'Please valid name')
        .matches(
            /^[A-Z\s]+$/, "name must be in capitals words"
        )
        .required('Required'),
    fatherName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .min(4, 'Please valid name')
        .matches(
            /^[A-Z\s]+$/, "name must be in capitals words"
        )
        .required('Required'),
    contactno: Yup.string()
        .matches(
            /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
            "Phone number is not valid"
        )
        .required('Required'),
    cnic: Yup.string()
        .matches(
            /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/,
            "Invalid CNIC No "
        )
        .required('Required'),
    email: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 charaters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Password must match')
        .required('Confirm password is required'),
})



const SignupApp3 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.AppReg)
    const { errorMessage, sucessfullMessage, sucessfull, loader, isError } = user

    useEffect(() => {
        if (sucessfull) {
            toast.success(sucessfullMessage)
            dispatch(clearState())
            alert('You are Registered successfully')
            navigate('/applogin')
        }
        if (isError) {
            toast.error(errorMessage);
            dispatch(clearState())


        }
    }, [sucessfull, isError]);

    return (
        <>
            <ToastContainer />
            <Formik
                initialValues={{
                    name: '',
                    fatherName: '',
                    contactno: '',
                    email: '',
                    cnic: '',
                    password: '',
                    confirmPassword: ''
                }}
                validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                    // console.log("this is submit console", values)
                    // resetForm({ values: '' })
                    dispatch(AppReg(values))
                }}

            >

                {formik => (
                    <div className='container signupApp3fulldiv ' style={{ marginTop: '100px', marginBottom: '50px' }} >
                        <div className='signuptext mt-5' >
                            <h1 className=" d-flex justify-content-center font-weight-bold pt-5 ">Sign Up</h1>
                        </div>
                        <Form className='mt-5'>
                            {/* {console.log(formik.values)} */}
                            <TextField label="Name" name="name" type="text" value={formik.values.firstName} />

                            <TextField label="Father Name" name="fatherName" type="text" />
                            <TextField label="Contact No" name="contactno" type="text" placeholder="03XXXXXXXXX" />
                            <TextField label="CNIC" name="cnic" type="text" placeholder="XXXXX-XXXXXXX-X" />
                            <TextField label="Email" name="email" type="email" />
                            <TextField label="Password" name="password" type="password" />
                            <TextField label="Confirm Password" name="confirmPassword" type="password" />
                            <div className='d-flex  justify-content-center pb-3 ' >
                                <button className="btn btn-danger mt-3" type="reset">Reset</button>
                                <button className="btn btn-primary mt-3" type="submit" style={{ marginLeft: '10px', width: "80px" }} >
                                    {loader ? "Loading.." : "Register"}</button>

                            </div>

                        </Form>
                    </div>
                )}
            </Formik >

        </>
    )
}
export default SignupApp3