import React, { useEffect, useState } from 'react'
import { Form, Button, Table } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearState } from '../../redux/features/DepttApplistSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DepttApplist } from '../../redux/services/DepttApplist';
import * as XLSX from 'xlsx'

const Deptthome = () => {

    const CurrentDate = new Date().getFullYear()

    const [session, setSession] = useState(CurrentDate + "-" + (CurrentDate + 4))
    const [formStatus, setFormStatus] = useState('open')
    // const [data, setData] = useState('')
    var serialNo = 0;
    // console.log(data)
    var userdetail = JSON.parse(localStorage.getItem("userdetail"))
    var depttname = userdetail.depttname

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userstate = useSelector(state => state.DepttApplist)
    const { errorMessage, sucessfullMessage, sucessfull, loader, isError, datalist } = userstate
    var list = datalist && datalist.slice()
    var newlist = datalist && list.sort((a, b) => a.aggregate > b.aggregate ? -1 : 1)
    // console.log(newlist)
    // setData(datalist)
    // console.log(datalist)
    useEffect(() => {
        if (sucessfull) {

            // toast.success(sucessfullMessage)
            // dispatch(clearState())
        }
        if (isError) {
            toast.error(errorMessage);
            dispatch(clearState())


        }
    }, [sucessfull, isError]);
    // console.log(deptt)

    const handleSubmit = (e) => {
        e && e.preventDefault()
        // console.log(session, formStatus, depttname, 'this is handlesubmit')
        dispatch(DepttApplist({ session, formStatus, depttname }))
        // console.log(session, formStatus, "this is console handlesubmit")
        // applicantslist();
    }

    useEffect(() => {
        handleSubmit()
    }, [])
    // console.log(newlist)
    const handleExport = () => {

        // console.log('handle export')

        var wb = XLSX.utils.book_new()

        // const myHeader = [{ _id: 'Form No', name: 'Name', fatherName: 'F/Name', contactno: 'Contact No' }]
        const myHeader = ["_id", "name", "fatherName", "interObtmarks", "matricObtmarks", 'aggregate', 'interSubject', 'matricSubject', 'session', 'contactno', 'email']
        // newlist.unshift(myHeader);

        const ws = XLSX.utils.json_to_sheet(newlist, { header: myHeader });
        ws['!cols'] = [{ width: 25 }, { width: 20 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 15 },
        { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }
        ];
        const range = XLSX.utils.decode_range(ws['!ref']);
        // console.log(range)
        const abc = range.e['c'] = myHeader.length - 32;
        // console.log(abc)
        ws['!ref'] = XLSX.utils.encode_range(range);
        // console.log(ws['!ref'])
        XLSX.utils.book_append_sheet(wb, ws, `${depttname}Applicantslist`);
        XLSX.writeFile(wb, `${depttname}Applicantslist.xlsx`);
    }


    return (
        <div className='container' >
            <ToastContainer />
            <div className='row text-center' style={{ marginTop: "100px" }}>
                <h1>Applicants Lists</h1>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-lg-6 col-sm-6'>
                        <Form.Select aria-label="Default select example" value={session} onChange={(e) => setSession(e.target.value)}>
                            <option value='' >Select the session</option>
                            <option value="2022-2026">2022-2026</option>
                            <option value="2023-2027">2023-2027</option>
                            <option value="2024-2028">2024-2028</option>
                            <option value="2025-2029">2025-2029</option>
                            <option value="2026-2030">2026-2030</option>
                            <option value="2027-2031">2027-2031</option>
                            <option value="2028-2032">2028-2032</option>
                            <option value="2029-2033">2029-2033</option>
                            <option value="2030-2034">2030-2034</option>
                            <option value="2031-2035">2031-2035</option>
                            <option value="2032-2036">2032-2036</option>
                            <option value="2033-2037">2033-2037</option>
                            <option value="2034-2038">2034-2038</option>
                            <option value="2035-2039">2035-2039</option>
                            <option value="2036-2040">2036-2040</option>
                            <option value="2037-2041">2037-2041</option>
                            <option value="2038-2042">2038-2042</option>
                            <option value="2039-2043">2039-2043</option>
                            <option value="2040-2044">2040-2044</option>
                        </Form.Select>
                    </div>
                    <div className='col-lg-6 col-sm-6'>
                        <Form.Select aria-label="Default select example" value={formStatus} onChange={(e) => setFormStatus(e.target.value)}>
                            <option value='' >Select a form type</option>
                            <option value="open">Open</option>
                            <option value="sports">Sports</option>
                            <option value="disable">Disable</option>
                            <option value="fata">FATA/Baluchistan</option>
                        </Form.Select>
                    </div>
                </div>
                <div className='row text-center'>
                    <div className='mt-3' >
                        <Button variant="outline-primary" type='submit'>
                            {loader ? "loading.." : "Show Applicants"}
                        </Button>

                    </div>
                </div>
            </Form>


            <div className='mt-5 float-end'>
                <h5 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Total Applicants : {newlist && newlist.length}</h5>
            </div>
            <div className='mt-5'>
                {

                    newlist.length == 0 ? <p>-</p> : <Button onClick={() => window.confirm('Are you sure to download this applicants list?',) && handleExport()}>Download</Button>


                }
                {/* {console.log(newlist)} */}

                <Table responsive striped bordered hover variant="ligth"
                    id='test-table-xls'
                >
                    <thead>
                        <tr>
                            <th>S/No</th>
                            <th>Name</th>
                            <th>Father Name</th>
                            <th>SSC Marks</th>
                            <th>FSC Marks</th>
                            <th>Aggregate</th>
                            <th>Detail</th>

                        </tr>
                    </thead>
                    <tbody>
                        {newlist ? newlist.map((elem) =>
                            <tr key={elem._id}>
                                <td>{serialNo = serialNo + 1}</td>
                                <td>{elem.name}</td>
                                <td>{elem.fatherName}</td>
                                <td>{elem.matricObtmarks}</td>
                                <td>{elem.interObtmarks}</td>
                                <td>{elem.aggregate.toFixed(2)}</td>
                                <td>
                                    <Link to={`/appdetail/${elem._id}`}>
                                        Detail
                                    </Link></td>
                            </tr>
                        ) : null}
                    </tbody>
                </Table>

            </div>

        </div>
    )
}

export default Deptthome