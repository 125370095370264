import React, { useEffect, useState } from 'react'
import AdmissionForm from './AdmissionForm'
import { ThreeDots } from 'react-loader-spinner';
import { API_URL } from "../../config"
const AdmissionDorN = () => {
    const [formstatus, setFormstatus] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        fetch(`${API_URL}/formdisplayread`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem("token")
            },
        })
            .then(response => {
                // console.log(response)
                if (response.ok) {
                    return response.json();
                }

            })
            .then(data => {
                setFormstatus(data.resp.formdisplay)
                setLoading(false)
                // console.log(data.resp.formdisplay)

            },
            ).catch(err => {

                console.log(err)
                setLoading(false)

            })
    }, [])
    return (
        <>
            {loading ? <div className='' style={{ display: 'flex', justifyContent: 'center', marginTop: '200px', marginBottom: '100px' }}>
                <ThreeDots color="#003396" height={80} width={80} />

            </div> :
                formstatus ? <AdmissionForm /> : <div className='text-center' style={{ marginTop: '250px', marginBottom: '250px' }}>
                    <p style={{ color: "#003396", fontSize: '25px' }}>Admission Off</p>
                </div>}
        </>
    )
}

export default AdmissionDorN