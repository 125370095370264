import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { formDeptts } from '../../redux/features/ApplyFormSlice';
import { API_URL } from "../../config"
const AdmissionDeptt = (props) => {
    const dispatch = useDispatch()
    const { values } = props
    setTimeout(() => {
        // console.log(values, "this is props")
        if (intersubject !== values) {
            // console.log('if condition')
            setIntersubject(values)
        }
    }, 2000);
    const [intersubject, setIntersubject] = useState('')
    const [depttlist, setDepttlist] = useState([])
    var list = depttlist && depttlist.slice()
    var newlistdeptt = depttlist && list.sort((a, b) => a.depttname.localeCompare(b.depttname));
    // console.log(depttlist)
    // console.log(intersubject, 'intersubject')
    const [deptts1, setDeptts1] = useState('')
    const [deptts2, setDeptts2] = useState('')
    const [deptts3, setDeptts3] = useState('')
    const [deptts4, setDeptts4] = useState('')
    const [deptts5, setDeptts5] = useState('')
    const [deptt1error, setDeptt1error] = useState(true)
    const [deptt2error, setDeptt2error] = useState(false)
    const [deptt3error, setDeptt3error] = useState(false)
    const [deptt4error, setDeptt4error] = useState(false)
    const [deptt5error, setDeptt5error] = useState(false)

    // console.log(deptts2, deptt2error, "this is .......")
    // let depttsDatas = {}
    const depttsDatas = { deptts1, deptts2, deptts3, deptts4, deptts5, deptt1error, deptt2error, deptt3error, deptt4error, deptt5error }
    // console.log(depttsDatas, 'form department data ')

    useEffect(() => {
        // if (deptts1) {
        // console.log('this is inner console')
        sessionStorage.setItem("admissionformdepttdata", JSON.stringify(depttsDatas))
        dispatch(formDeptts(depttsDatas))
        // }
        // console.log('this is useeffect console')

    }, [deptts1, deptts2, deptts3, deptts4, deptts5, deptt1error, deptt2error, deptt3error, deptt4error, deptt5error])


    const deptts1check = () => {
        if (deptts1 == "") {
            setDeptt1error(true)
            return false
        }
        else {
            setDeptt1error(false)
            return true
        }
    }
    const deptts2check = () => {

        if (deptts2 === '') {
            setDeptt2error(false)


            return true
        }
        if (deptts2 === deptts1) {
            setDeptt2error(true)


            return false
        }
        else {

            setDeptt2error(false)
            return true
        }
    }
    const deptts3check = () => {

        if (deptts3 === '') {
            setDeptt3error(false)


            return true
        }
        if (deptts3 === deptts1 || deptts3 === deptts2) {
            setDeptt3error(true)


            return false
        }
        else {

            setDeptt3error(false)
            return true
        }
    }
    const deptts4check = () => {

        if (deptts4 === '') {
            setDeptt4error(false)


            return true
        }
        if (deptts4 === deptts1 || deptts4 === deptts2 || deptts4 === deptts3) {
            setDeptt4error(true)


            return false
        }
        else {

            setDeptt4error(false)
            return true
        }
    }
    const deptts5check = () => {

        if (deptts5 === '') {
            setDeptt5error(false)


            return true
        }
        if (deptts5 === deptts1 || deptts5 === deptts2 || deptts5 === deptts3 || deptts5 === deptts4) {
            setDeptt5error(true)


            return false
        }
        else {

            setDeptt5error(false)
            return true
        }
    }
    useEffect(() => {
        if (!intersubject) {
            setDepttlist([])
        }
        if (intersubject) {
            // console.log("useeffect ")
            fetch(`${API_URL}/depttlist`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt")
                },
                body: JSON.stringify({


                    data: intersubject
                })

            })
                .then(response => response.json())
                .then(data =>

                    setDepttlist(data.deptt)

                );

        }
    }, [intersubject])
    return (
        <div>
            <hr />
            {newlistdeptt.length !== 0 &&
                <div className="d-flex justify-content-center" style={{ fontWeight: 'bold' }} >
                    <div className="p-2  ">-----Select Departments-----</div>
                </div>
            }
            {newlistdeptt.length !== 0 &&
                <div>
                    <div className='text-center mb-3'>
                        Note: Atleast one department must be select
                    </div>
                </div>
            }
            {newlistdeptt.length !== 0 &&

                <div className='row'>
                    <div className='col-6 text-center mt-3'>


                        <select label="" name="deptts1"
                            onBlur={deptts1check}
                            onChange={(e) => setDeptts1(e.target.value)}>

                            <option value=""> Select</option>
                            {newlistdeptt && newlistdeptt.map((res) => (
                                <option key={res._id} value={res.depttname}>
                                    {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                </option>
                            ))}
                        </select>
                        {deptt1error && <p style={{ color: "red" }}>Must be select this field</p>}
                    </div>
                    <div className='col-6 text-center mt-3'>

                        <select label="" name="deptts2"
                            onBlur={deptts2check}
                            onChange={(e) => setDeptts2(e.target.value)}>

                            <option value=""> Select</option>
                            {newlistdeptt && newlistdeptt.map((res) => (
                                <option

                                    key={res._id} value={res.depttname} >
                                    {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                </option>
                            ))}

                        </select>



                        {deptt2error && <p style={{ color: "red" }}>Already selected</p>}
                    </div>
                    <div className='col-6 text-center mt-3 ' >

                        <select label="" name="deptts3"
                            onBlur={deptts3check}
                            onChange={(e) => setDeptts3(e.target.value)}>

                            <option value=""> Select</option>
                            {newlistdeptt && newlistdeptt.map((res) => (
                                <option key={res._id} value={res.depttname}>
                                    {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                </option>
                            ))}

                        </select>
                        {deptt3error && <p style={{ color: "red" }}>Already selected</p>}
                    </div>
                    <div className='col-6 text-center mt-3 mb-3'>


                        <select label="" name="deptts4"
                            onBlur={deptts4check}
                            onChange={(e) => setDeptts4(e.target.value)}>

                            <option value=""> Select</option>
                            {newlistdeptt && newlistdeptt.map((res) => (
                                <option key={res._id} value={res.depttname}>
                                    {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                </option>
                            ))}
                        </select>
                        {deptt4error && <p style={{ color: "red" }}>Already selected</p>}
                    </div>

                    {/* <div className='col-12 text-center mt-3 mb-3'>

                        <select label="" name="deptts5"
                            onBlur={deptts5check}
                            onChange={(e) => setDeptts5(e.target.value)}>

                            <option value=""> Select</option>
                            {newlistdeptt && newlistdeptt.map((res) => (
                                <option key={res._id} value={res.depttname}>
                                    {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                </option>
                            ))}
                        </select>
                        {deptt5error && <p style={{ color: "red" }}>Already selected</p>}
                    </div> */}
                    <hr />
                </div>
            }

        </div>
    )
}

export default AdmissionDeptt