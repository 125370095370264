import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../../config"
const DepttUpdatepass = () => {
    const [loader, setLoader] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [conformPassword, setConformPassword] = useState('')
    const navigate = useNavigate()
    const deptt = JSON.parse(localStorage.getItem('userdetail'))
    const { _id } = deptt
    // console.log(_id)
    const handleSubmit = () => {

        if (!oldPassword) {
            return toast.error("please enter password")
        }
        if (!newPassword) {
            return toast.error("please enter new password")
        }
        if (!conformPassword) {
            return toast.error("please enter conform password")
        }
        if (newPassword.length < 6) {
            return toast.error("password is too short")
        }
        if (newPassword !== conformPassword) {
            return toast.error("new password should be match conform password")
        }
        setLoader(true)
        fetch(`${API_URL}/depttspassupdate/${_id}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                oldPassword,
                newPassword
            })
        }).then(res => res.json())
            .then(data => {
                setLoader(false)
                // console.log(data)
                if (data.error) {
                    toast.error(data.error)
                } else {
                    // toast.success(data.message)
                    alert("Password Changed Successfully")
                    navigate('/deptthome')
                }
            }).catch(err => {
                setLoader(false)
                toast.error('server down')
                // console.log(err)
            })
    }

    return (

        <div className='container'>
            <ToastContainer />
            <div className='row text-center' style={{ marginTop: '150px' }}>
                <h3 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>Change Password</h3>
            </div>
            <div className='row mt-5 d-flex justify-content-center'>
                <div className='col-lg-6 col-sm-12'>
                    <div className="">
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}>
                            <input type="password" placeholder="Old Password" className="form-control"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}

                            />
                            <input type="password" placeholder="New Password" className="mt-3 form-control"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}

                            />
                            <input type="password" placeholder="Conform Password" className="mt-3 form-control"
                                value={conformPassword}
                                onChange={(e) => setConformPassword(e.target.value)}

                            />
                            {loader ? <button className=" mt-2 btn btn-primary float-end" disabled>Loading...</button> :
                                <button className=" mt-2 btn btn-primary float-end" type='submit'>Change Password</button>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DepttUpdatepass