import React from 'react'
import { Link } from 'react-router-dom'
// import Footer from './Footer'
import OurMissionV from './OurMissionV'
import SliderClass from './SliderClass'

const AppHome = () => {
    return (
        <div className='fluid-container' style={{ marginTop: '55px' }}>

            <SliderClass />
            <OurMissionV />
            {/* <Footer /> */}

        </div>
    )
}

export default AppHome