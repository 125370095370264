import React, { useEffect, useRef, useState, } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { AppReg } from '../../redux/services/AppUser';
import { MySelect, MyCheckbox } from './AdmissionForm2'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearState } from '../../redux/features/ApplyFormSlice';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ApplyForm } from '../../redux/services/ApplyForm';
import uosimage from '../../assets/universityofswabi.jpg'
import './AdmissionForm.css'
import axios from 'axios';
import { API_URL } from "../../config"
import AdmissionDeptt from './AdmissionDeptt';
const SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];
const SUPPORTED_FORMATS2 = ['image/png', 'image/jpeg', 'image/jpg', 'image/pdf'];

const KErrorMessage = ({ name }) => {
    return (
        <div style={{ color: "red" }}>
            {/* <br /> */}
            <ErrorMessage name={name} className='error' />
        </div>
    );
};
const TextField = ({ label, ...props }) => {
    // console.log(label)
    // console.log(props)
    const [field, meta] = useField(props);
    // console.log(field.name)
    // console.log(meta)
    return (
        <div className="mb-2  ">
            <label htmlFor={field.name}>{label}</label>
            <input
                className={`form-control  ${meta.touched && meta.error && 'is-invalid'}`}
                {...field} {...props}
                autoComplete="off"
            />
            <ErrorMessage component="div" name={field.name} className="error" style={{ color: 'red' }} />
        </div>
    )
}
const PreviewImage = ({ picture }) => {
    // console.log(picture)
    const [preview, setPreview] = useState(null)
    const reader = new FileReader();
    reader.readAsDataURL(picture);
    reader.onload = () => {
        setPreview(reader.result)
        // console.log(preview)
    };
    return (
        <div>
            {preview ? <img src={preview} alt='pic' width="150px" height='150px' /> : 'Loading...'}
        </div>
    )
}



// This is form for applying for admission to university of swabi control useeffect




const validate = Yup.object({
    name: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .min(4, 'Please valid name')
        .matches(
            /^[A-Z\s]+$/, "name must be in capitals words"
        )
        .required('Required'),
    fatherName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .min(4, 'Please valid f/name')
        .matches(
            /^[A-Z\s]+$/, "father name must be in capitals words"
        )
        .required('Required'),
    contactno: Yup.string()
        .matches(
            /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm,
            "Phone number is not valid"
        )
        .required('Required'),
    cnic: Yup.string()
        .matches(
            /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/,
            "Invalid CNIC No "
        )
        .required('Required'),
    email: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
    fatherCnic: Yup.string()
        .matches(
            /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/,
            "Invalid CNIC No "
        )
        .required('Required'),
    fatherOccuption: Yup.string()
        .required('Required'),
    Dob: Yup.string()
        .required('Required'),
    religion: Yup.string()
        .required('Required'),
    nationality: Yup.string()
        .required('Required'),
    permanentAddress: Yup.string()
        .required('Required'),
    postalAddress: Yup.string()
        .required('Required'),
    matricBoardname: Yup.string()
        .required('Required'),
    matricRollno: Yup.string()
        .required('Required'),
    matricpassingyear: Yup.string()
        .min(4, 'Must be exactly 4 characters')
        .max(4, 'Must be exactly 4 characters')
        .required('Required'),
    matricObtmarks: Yup.string()
        .required('Required')
        .test(
            'Is positive?',
            "Invalid marks",
            (value) => value > 0
        ),
    matricTmarks: Yup.number()
        .typeError('Must be a number')
        .required('Required')
        .when('matricObtmarks', (matricObtmarks) => {
            if (matricObtmarks) {
                return Yup.number()
                    .min(matricObtmarks, 'Obtained marks must be less than total marks')
                    .typeError('Total marks must be greater than obtained marks')
                    .required('Required')

            }
        }),
    // .required('Required'),
    matricSubject: Yup.string()
        .required('Required'),
    interBoardname: Yup.string()
        .required('Required'),
    interRollno: Yup.string()
        .required('Required'),
    interpassingyear: Yup.string()
        .min(4, 'Must be exactly 4 characters')
        .max(4, 'Must be exactly 4 characters')
        .required('Required'),
    interObtmarks: Yup.number()

        .required('Required')
        .test(
            'Is positive?',
            "Invalid marks",
            (value) => value > 0
        ),
    interTmarks: Yup.number()
        .typeError('Must be a number')
        .required('Required')
        .when('interObtmarks', (interObtmarks) => {
            if (interObtmarks) {
                return Yup.number()
                    .min(interObtmarks, 'Obtained marks must be less than total marks')
                    .typeError('Total marks must be greater than obtained marks')
                    .required('Required')
            }
        }),
    interSubject: Yup.string()
        .required('Required'),
    acceptedTerms: Yup.boolean()
        .required('Required')
        .oneOf([true], 'You must accept'),
    formStatus: Yup.string()
        .oneOf(
            ['open', 'sports', 'disable', 'fata'],
            'Invalid Job Type'
        )
        .required('Required'),
    session: Yup.string()
        .required('Required'),
    // deptts1: Yup.string()
    //     .required('Please select one department at least(This one must be filled)'),
    // deptts2: Yup.string()

    //     .when('deptts1', (deptts1, schema) => {

    //         return schema.test({
    //             test: deptts2 => (!!deptts1 && deptts2 != deptts1 || deptts2 === ""),
    //             message: "Already selected",
    //         })
    //     }),
    // deptts2: Yup.string().when("deptts1", {
    //     is: value => value && value != [Yup.ref("deptts1")],
    //     then: Yup.string().equals([Yup.ref('deptts1'), null], 'Already selected'),
    //     otherwise: Yup.string(),
    // }),

    picture: Yup
        .mixed()
        .nullable()
        .required("Required")
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
        ),


    sscDmc: Yup
        .mixed()
        .nullable()
        .required("Required")
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS2.includes(value?.type))
        ),
    sscCertificate: Yup
        .mixed()
        .nullable()
        .required("Required")
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS2.includes(value?.type))
        ),
    hsscDmc: Yup
        .mixed()
        .nullable()
        .required("Required")
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS2.includes(value?.type))
        ),
    hsscCertificate: Yup
        .mixed()
        .nullable()
        .required("Required")
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS2.includes(value?.type))
        ),
    domicilePic: Yup
        .mixed()
        .nullable()
        .required("Required")
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS2.includes(value?.type))
        ),
    cnicPic: Yup
        .mixed()
        .nullable()
        .required("Required")
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS2.includes(value?.type))
        ),
    bankSlipPic: Yup
        .mixed()
        .nullable()
        .required("Required")
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS2.includes(value?.type))
        ),
    hifazCertificate: Yup
        .mixed()
        .nullable()
        .test(
            "FILE_SIZE",
            "uploaded pic size is too large",
            (value) => !value || (value && value.size <= 1024 * 1024)
        )
        .test(
            "FILE_TYPE",
            "uploaded pic has unsupported format",
            (value) => !value || (value && SUPPORTED_FORMATS2.includes(value?.type))
        ),










})



const AdmissionForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userstate = useSelector(state => state.ApplyForm)
    const { errorMessage, sucessfullMessage, sucessfull, loader, isError } = userstate
    const userselectdeptts = useSelector(state => state.ApplyForm.depttsDatas)
    const { deptts1, deptts2, deptts3, deptts4, deptts5, deptt1error, deptt2error, deptt3error, deptt4error, deptt5error } = userselectdeptts
    // console.log(userselectdeptts.length !== 0 && userselectdeptts, "this is userselectdeptts lasjdfkjaslfjlsdajlkfj")
    // console.log(deptt1error, deptt2error, deptt3error, deptt4error, deptt5error)
    const [depttlist, setDepttlist] = useState([])
    // console.log(depttlist, 'department list ')
    var list = depttlist && depttlist.slice()
    var newlistdeptt = depttlist && list.sort((a, b) => a.depttname.localeCompare(b.depttname));
    // console.log(depttlist)
    // console.log(newlistdeptt)
    const [formstatus, setFormstatus] = useState(false)
    // console.log(deptts2data, 'data fo select ')
    useEffect(() => {

        fetch(`${API_URL}/formdisplayread`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem("token")
            },
        })
            .then(response => {
                // console.log(response)
                if (response.ok) {
                    return response.json();
                }

            })
            .then(data => {
                setFormstatus(data.resp.formdisplay)
                // console.log(data.resp.formdisplay)

            },
            ).catch(err => {

                console.log(err)

            })
    }, [])


    const setCurrentDate = new Date().getFullYear()
    // console.log(setCurrentDate)

    // useEffect(() => {


    //     fetch('http://localhost:1000/depttlist', {
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": "Bearer " + localStorage.getItem("jwt")
    //         },
    //         body: JSON.stringify({


    //             data: fscsubject
    //         })

    //     })
    //         .then(response => response.json())
    //         .then(data =>

    //             setDepttlist(data.deptt)

    //         );


    // }, [fscsubject])
    useEffect(() => {
        if (sucessfull) {
            toast.success(sucessfullMessage)
            dispatch(clearState())
            sessionStorage.removeItem('admissionformdepttdata');
            alert('Successfully your form submitted')
            dispatch(clearState())
            navigate('/')
        }
        if (isError) {
            toast.error(errorMessage);
            dispatch(clearState())


        }
    }, [sucessfull, isError]);

    const userDetail = localStorage.getItem('userdetail')
    const user = JSON.parse(userDetail)
    // console.log(user.name)
    // const { name, fatherName, contactno, email, cnic, _id } = user
    // console.log(name, fatherName, contactno, email, cnic, _id)
    const picRaf = useRef(null)
    const sscDmcRaf = useRef(null)
    const sscCertificateRaf = useRef(null)
    const hsscDmcRaf = useRef(null)
    const hsscCertificateRaf = useRef(null)
    const domicilePicRaf = useRef(null)
    const cnicPicRaf = useRef(null)
    const bankSlipPicRaf = useRef(null)
    // const formRef = useRef();
    // console.log(formRef, "this is form ref ......")
    // const fscsubjectRaf = useRef(null);


    const [loading, setLoading] = useState(false)
    const [pictureU, setPictureU] = useState(undefined)
    const [picUrls, setPicUrls] = useState([])
    // console.log(picUrls)
    // console.log(pictureU)
    const uploadPics = (values) => {
        setLoading(true)
        if (!deptts1) {
            // console.log("department if condition")
            setLoading(false)
            return toast.error("Please select department")
        }
        if (deptt1error || deptt2error || deptt3error || deptt4error || deptt5error) {
            setLoading(false)
            // console.log("deptterror if conditions ;.......")
            return toast.error("Please check the department error")
        }
        var PicUrls = picUrls
        // console.log(PicUrls)
        // console.log(pictureU && PicUrls.length == 7)
        if (pictureU && PicUrls.length == 7) {
            // console.log('2')
            // console.log(pictureU, PicUrls)
            dispatch(ApplyForm({
                values, pictureU, PicUrls, deptts1,
                deptts1, deptts2, deptts3, deptts4, deptts5
            }))
            setLoading(false)
        }
        else {
            // console.log('1')
            const { picture, sscDmc, sscCertificate, hsscDmc, hsscCertificate, domicilePic, cnicPic, bankSlipPic } = values
            var files = []
            files.push(sscDmc, sscCertificate, hsscDmc, hsscCertificate, domicilePic, cnicPic, bankSlipPic)

            var PicUrls = []
            const data = new FormData();
            data.append('file', picture);
            data.append("upload_preset", "AdmissionForm");
            data.append("cloud_name", "dbdxsvxda")



            fetch("https://api.cloudinary.com/v1_1/dbdxsvxda/image/upload", {
                method: "post",
                body: data
            }).then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    `Network response was not ok: ${response.status}`

                )
            })
                .then(data => {
                    var pictureU = data.url
                    setPictureU(pictureU)
                    if (pictureU) {

                        const uploaders = files.map(file => {
                            const formData = new FormData();
                            formData.append("file", file);
                            formData.append("tags", `codeinfuse, medium, gist`);
                            formData.append("upload_preset", "AdmissionForm");
                            formData.append("api_key", "113546834969548");
                            formData.append("timestamp", (Date.now() / 1000) | 0);


                            return axios.post("https://api.cloudinary.com/v1_1/dbdxsvxda/image/upload", formData, {
                                headers: { "X-Requested-With": "XMLHttpRequest" },
                            }).then(response => {
                                const data = response.data;
                                const fileURL = data.secure_url
                                PicUrls.push(fileURL)
                                setPicUrls(PicUrls)
                            }).catch(err => {
                                console.log(err, 'error')
                                // toast.error(err.message)
                            })
                        })
                        axios.all(uploaders).then(() => {
                            // console.log(PicUrls)
                            // console.log(pictureU)
                            dispatch(ApplyForm({
                                values, PicUrls, pictureU, deptts1,
                                deptts1, deptts2, deptts3, deptts4, deptts5
                            }))
                            setLoading(false)

                        })
                    }

                }).catch(err => {
                    toast.error('Internet Issue')
                    console.log(err)
                    setLoading(false)
                })
        }

    }




    return (
        <>
            <ToastContainer />
            <Formik

                initialValues={{
                    formStatus: '',
                    session: setCurrentDate + "-" + (setCurrentDate + 4),
                    Dob: '',
                    gender: '',
                    religion: '',
                    nationality: '',
                    fatherCnic: '',
                    fatherOccuption: '',
                    permanentAddress: '',
                    postalAddress: '',
                    hafizQuran: '',
                    hostalneed: '',
                    transportneed: '',
                    name: user.name,
                    fatherName: user.fatherName,
                    contactno: user.contactno,
                    email: user.email,
                    cnic: user.cnic,
                    matricRollno: '',
                    matricBoardname: '',
                    matricpassingyear: '',
                    matricObtmarks: '',
                    matricTmarks: '',
                    matricSubject: '',
                    interRollno: '',
                    interBoardname: '',
                    interpassingyear: '',
                    interObtmarks: '',
                    interTmarks: '',
                    interSubject: '',
                    acceptedTerms: '',
                    picture: null,
                    sscDmc: null,
                    sscCertificate: null,
                    hsscDmc: null,
                    hsscCertificate: null,
                    cnicPic: null,
                    domicilePic: null,
                    bankSlipPic: null,




                }}
                validationSchema={validate}
                onSubmit={(values, { resetForm }) => {
                    // if (deptt1error, deptt2error, deptt3error, deptt4error, deptt5error) {

                    // console.log("this is submit console", values)
                    window.confirm('This will take few seconds do not cancle during submitting',) && uploadPics(values)
                    // uploadPics(values)
                    // }


                }}

            >

                {formik => (
                    <div className='container admissionformfulldiv ' style={{ marginTop: '100px', marginBottom: '50px' }} >
                        <div className='row'>
                            <div className='col-lg-4 col-md-12 col-sm-12 admissionformlogodiv '>
                                <img className='admissionformlogo' src={uosimage} alt="University Of Swabi" />
                            </div>
                            <h1 className='col-lg-8 col-sm-12 Uosnametitle  '   >University Of Swabi<br /><p className='admissionsubtext' >Admission Form</p></h1>
                        </div>
                        <div className='text-center'>
                            <p className='underformtext '>(Undergraduate Form)</p>
                        </div>
                        <hr />

                        <Form className='mt-2'>
                            {/* {console.log(formik.values)} */}
                            <div className='row'>
                                {/* <div className='d-flex justify-content-start'> */}

                                <div className=' col-lg-6 col-sm-6 col-6 ' >
                                    <KErrorMessage name='picture' />
                                    {formik.values.picture && <PreviewImage picture={formik.values.picture} />}
                                    <input label="Picture" name="picture" type="file"
                                        ref={picRaf}
                                        hidden

                                        onChange={(event) => {
                                            formik.setFieldValue("picture", event.target.files[0]);
                                        }}

                                    />

                                    <button className="btn btn-primary"
                                        style={{ marginTop: '5px', width: '150px' }}
                                        type='button'
                                        onClick={() => {
                                            picRaf.current.click();

                                        }}
                                    >
                                        Upload Picture
                                    </button>

                                    {/* </div> */}
                                </div>
                                {/* </div>

                            <div className='row '> */}
                                {/* <div className='d-flex justify-content-start'> */}

                                <div className=' col-lg-6 col-sm-6 col-6 d-flex f-column align-items-end justify-content-end '    >
                                    <div className='sessionandformstatus float-end'>

                                        <TextField label="Session" name="session" type="text" disabled={true}
                                            className='sessionandformstatus'

                                        />
                                        <MySelect label="" name="formStatus"
                                            className="sessionandformstatus"
                                        >

                                            <option value="">Select a form type</option>
                                            <option value="open">Open</option>
                                            <option value="sports">Sports</option>
                                            <option value="disable">Disable</option>
                                            <option value="fata">FATA/Baluchistan</option>
                                        </MySelect>
                                    </div>
                                </div>
                                {/* </div> */}

                                {/* <div className='d-flex justify-content-start'> */}

                                {/* </div> */}


                            </div>
                            <div className='row  mt-2'>
                                <div className='col-lg-6 col-sm-6'>

                                    <TextField label="Name" name="name" type="text" />
                                </div>
                                <div className='col-lg-6 col-sm-6'>

                                    <TextField label="Father Name" name="fatherName" type="text" />
                                </div>
                            </div>
                            <div className='row  mt-2'>
                                <div className='col-lg-6 col-sm-6'>

                                    <TextField label="Contact No" name="contactno" type="text" />
                                </div>
                                <div className='col-lg-6 col-sm-6'>
                                    <TextField label="CNIC" name="cnic" type="text" placeholder="XXXXX-XXXXXXX-X" />
                                </div>
                            </div>
                            <div className='row  mt-2'>
                                <div className='col-lg-6 col-sm-6'>

                                    <TextField label="Father Cnic" name="fatherCnic" type="text" placeholder="XXXXX-XXXXXXX-X" />
                                </div>
                                <div className='col-lg-6 col-sm-6'>
                                    <TextField label="Father Occupation" name="fatherOccuption" type="text" />
                                </div>
                            </div>
                            <div className='row  mt-2'>
                                <div className='col-lg-6 col-sm-6'>

                                    <TextField label="Email" name="email" type="email" />
                                </div>
                                <div className='col-lg-6 col-sm-6'>
                                    <TextField label="Date of Birth" name="Dob" type="date" />
                                </div>
                            </div>
                            <div className='row  mt-2'>
                                <div className='col-lg-6 col-sm-6'>
                                    <TextField label="Religion" name="religion" type="text" />
                                </div>
                                <div className='col-lg-6 col-sm-6'>
                                    {/* <TextField label="Nationaliy" name="nationality" type="select" /> */}
                                    <label>Nationality</label>
                                    <MySelect label="" name="nationality" style={{ width: '100%', height: '36px' }} >
                                        <option value="">Select</option>
                                        <option value="Pakistani">Pakistani</option>
                                        <option value="Foreign">Foreign</option>

                                    </MySelect>
                                </div>
                            </div>
                            <TextField label="Permanent Address" name="permanentAddress" type="text" />
                            <TextField label="Postal Address" name="postalAddress" type="text" />

                            <div id="my-radio-group">Gender</div>
                            <div role="group" aria-labelledby="my-radio-group">
                                <label className='p-2'>
                                    <Field type="radio" name="gender" value="male" />
                                    Male
                                </label>
                                <label className='p-2'>
                                    <Field type="radio" name="gender" value="female" />
                                    Female
                                </label>

                            </div>

                            <div className='row'>
                                <div className='col-md-6  ' >
                                    <div className='d-flex flex-column mt-2'>

                                        <label>Domicile Picture</label>
                                        <input label="Domicile Picture" name="domicilePic" type="file"
                                            ref={domicilePicRaf}
                                            // hidden

                                            onChange={(event) => {
                                                formik.setFieldValue("domicilePic", event.target.files[0]);
                                            }}

                                        />
                                        <KErrorMessage name='domicilePic' />

                                    </div>
                                </div>
                                <div className='col-md-6 '>

                                    <div className='d-flex flex-column mt-2'>
                                        <label>CNIC Picture</label>
                                        <input label="" name="cnicPic" type="file"
                                            ref={cnicPicRaf}
                                            // hidden

                                            onChange={(event) => {
                                                formik.setFieldValue("cnicPic", event.target.files[0]);
                                            }}

                                        />
                                        <KErrorMessage name='cnicPic' />
                                    </div>
                                </div>
                                <div className='col-md-6 '>

                                    <div className='d-flex flex-column mt-2'>
                                        <label>Bank Slip Picture</label>
                                        <input label="" name="bankSlipPic" type="file"
                                            ref={bankSlipPicRaf}
                                            // hidden

                                            onChange={(event) => {
                                                formik.setFieldValue("bankSlipPic", event.target.files[0]);
                                            }}

                                        />
                                        <KErrorMessage name='bankSlipPic' />
                                    </div>
                                </div>
                            </div>
                            {/* <hr />
                            <div className="d-flex justify-content-center" style={{ fontWeight: 'bold' }} >
                                <div className="p-2  ">-----Select Departments-----</div>
                            </div>
                            <div>
                                <div className='text-center mb-3'>
                                    Note: Atleast one department must be select
                                </div>
                            </div>
                            <div className='d-flex justify-content-around'>

                                <MySelect label="" name="deptts1" >

                                    <option value=""> Select</option>
                                    {newlistdeptt && newlistdeptt.map((res) => (
                                        <option key={res._id} value={res.depttname}>
                                            {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                        </option>
                                    ))}
                                </MySelect>



                                <MySelect label="" name="deptts2" >

                                    <option value=""> Select</option>
                                    {newlistdeptt && newlistdeptt.map((res) => (
                                        <option
                                            // onChange={


                                            //     deptts2check(formik.values)

                                            // }
                                            key={res._id} value={res.depttname} >
                                            {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                        </option>
                                    ))}

                                </MySelect>

                            </div>
                            <div className='d-flex justify-content-around mt-3'>

                                <MySelect label="" name="deptts3">

                                    <option value=""> Select</option>
                                    {newlistdeptt && newlistdeptt.map((res) => (
                                        <option key={res._id} value={res.depttname}>
                                            {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                        </option>
                                    ))}

                                </MySelect>



                                <MySelect label="" name="deptts4" >

                                    <option value=""> Select</option>
                                    {newlistdeptt && newlistdeptt.map((res) => (
                                        <option key={res._id} value={res.depttname}>
                                            {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                        </option>
                                    ))}
                                </MySelect>

                            </div>
                            <div className='d-flex justify-content-around mt-3'>

                                <MySelect label="" name="deptts5">

                                    <option value=""> Select</option>
                                    {newlistdeptt && newlistdeptt.map((res) => (
                                        <option key={res._id} value={res.depttname}>
                                            {res.depttname.charAt(0).toUpperCase() + res.depttname.slice(1)}
                                        </option>
                                    ))}
                                </MySelect>

                            </div> */}


                            <div className="d-flex justify-content-center mt-4 mb-3" style={{ backgroundColor: 'darkgray' }}>
                                <div className="p-2 bd-highlight"><h5>Academic Qualification</h5></div>
                            </div>

                            {/* Academic Record .......... */}
                            <div className='d-flex justify-content-center'>

                                <h6>----Matriculation Detials----</h6>
                            </div>

                            <div className='row  mt-3'>
                                <div className='col-lg-3 col-sm-6'>
                                    <TextField label="Passing Year" name="matricpassingyear" type='number' />
                                </div>
                                <div className='col-lg-3 col-sm-6'>
                                    <TextField label="Board Roll/No" name="matricRollno" type="text" />
                                </div>
                                <div className='col-lg-3 col-sm-6'>
                                    <TextField label="Obtain Marks" name="matricObtmarks" type="number" />
                                </div>
                                <div className='col-lg-3 col-sm-6'>
                                    <TextField label="Total Marks" name="matricTmarks" type="number" />
                                </div>

                            </div>
                            <div className=' row  '>

                                <div className='col-md-6 mt-3'>
                                    {/* <TextField label="Matric Subject" name="matricSubject" type="text" placeholder="Science/Arts etc." /> */}
                                    <label>Select subject</label>
                                    <MySelect label="" name="matricSubject" style={{ width: '150px' }} >
                                        <option value="">Select Subject</option>
                                        <option value="Science">Science</option>
                                        <option value="Arts">Arts</option>
                                        <option value="TSC">TSC</option>
                                        <option value="Other">Other</option>
                                    </MySelect>
                                </div>

                                <div className='col-md-6 mt-3'>
                                    {/* <TextField label="Matric Board Name" name="matricBoardname" type="text" /> */}
                                    <label>Select Board</label>
                                    <MySelect label="" name="matricBoardname" style={{ width: '150px' }}>

                                        <option value="">Select </option>
                                        <option value="BISE MARDAN">BISE MARDAN</option>
                                        <option value="BISE PESHAWAR">BISE PESHAWAR</option>
                                        <option value="BISE SWAT">BISE SWAT</option>
                                        <option value="BISE MALAKAND">BISE MALAKAND</option>
                                        <option value="BISE ABBOTTABAD">BISE ABBOTTABAD</option>
                                        <option value="BISE BANNU">BISE BANNU</option>
                                        <option value="BISE KOHAT">BISE KOHAT</option>
                                        <option value="BISE DERA ISMAIL KHAN">BISE DI KHAN</option>
                                        <option value="BISE PUNJAB">BISE PUNJAB</option>
                                        <option value="BISE SINDH">BISE SINDH</option>
                                        <option value="BISE BALUCHISTAN">BISE BALUCHISTAN</option>
                                        <option value="Technical Board Peshawar">TECH BOARD PESHAWAR</option>
                                        <option value="Technical Board punjab">TECH BOARD PUNJAB</option>
                                        <option value="Technical Board sindh">TECH BOARD SINDH</option>
                                        <option value="Technical Board baluchistan">TECH BOARD BALUCHISTAN</option>
                                        <option value="Other">Other</option>
                                    </MySelect>

                                </div>

                            </div>


                            <div className='row'>
                                <div className='col-md-6  ' >
                                    <div className='d-flex flex-column mt-2'>

                                        <label>SSC DMC</label>
                                        <input label="SSC DMC" name="sscDmc" type="file"
                                            ref={sscDmcRaf}
                                            // hidden

                                            onChange={(event) => {
                                                formik.setFieldValue("sscDmc", event.target.files[0]);
                                            }}

                                        />
                                        <KErrorMessage name='sscDmc' />

                                    </div>
                                </div>
                                <div className='col-md-6 '>

                                    <div className='d-flex flex-column mt-2'>
                                        <label>SSC Certificate</label>
                                        <input label="SSC Certificate" name="sscCertificate" type="file"
                                            ref={sscCertificateRaf}
                                            // hidden

                                            onChange={(event) => {
                                                formik.setFieldValue("sscCertificate", event.target.files[0]);
                                            }}

                                        />
                                        <KErrorMessage name='sscCertificate' />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <hr />
                            <div className='d-flex justify-content-center'>

                                <h6>----Intermediate Detials----</h6>
                            </div>

                            <div className='row  mt-2'>
                                <div className='col-lg-3 col-sm-6'>
                                    <TextField label="Passing Year" name="interpassingyear" type='number' />
                                </div>
                                <div className='col-lg-3 col-sm-6'>
                                    <TextField label="Board Roll/No" name="interRollno" type="text" />
                                </div>
                                <div className='col-lg-3 col-sm-6'>
                                    <TextField label="Obtain Marks" name="interObtmarks" type="number" />
                                </div>
                                <div className='col-lg-3 col-sm-6'>
                                    <TextField label="Total Marks" name="interTmarks" type="number" />
                                </div>

                            </div>
                            <div className=' row  mt-3'>

                                <div className='col-md-6 mt-3'>
                                    <label>Select subject</label>
                                    <MySelect label="" name="interSubject" style={{ width: '150px' }}



                                        onChange={(event) => {
                                            formik.setFieldValue("interSubject", event.target.value);
                                        }}


                                    >
                                        <option value="">Select </option>
                                        <option value="FSc Pre-Eng">FSc Pre-Eng</option>
                                        <option value="FSc Pre-Med">FSc Pre-Med</option>
                                        <option value="FCS">FCS</option>
                                        <option value="FSc(Health,Den etc)">FSc(Health,Den etc)</option>
                                        <option value="FA">FA</option>
                                        <option value="D-com">D-com/I-com</option>
                                        <option value="DAE">DAE</option>

                                    </MySelect>
                                </div>

                                <div className='col-md-6 mt-3'>
                                    <label>Select Board</label>
                                    <MySelect label="" name="interBoardname" style={{ width: '150px' }} >

                                        <option value="">Select</option>
                                        <option value="BISE MARDAN">BISE MARDAN</option>
                                        <option value="BISE PESHAWAR">BISE PESHAWAR</option>
                                        <option value="BISE SWAT">BISE SWAT</option>
                                        <option value="BISE MALAKAND">BISE MALAKAND</option>
                                        <option value="BISE ABBOTTABAD">BISE ABBOTTABAD</option>
                                        <option value="BISE BANNU">BISE BANNU</option>
                                        <option value="BISE KOHAT">BISE KOHAT</option>
                                        <option value="BISE DERA ISMAIL KHAN">BISE DI KHAN</option>
                                        <option value="BISE PUNJAB">BISE PUNJAB</option>
                                        <option value="BISE SINDH">BISE SINDH</option>
                                        <option value="BISE BALUCHISTAN">BISE BALUCHISTAN</option>
                                        <option value="Technical Board Peshawar">TECH BOARD PESHAWAR</option>
                                        <option value="Technical Board punjab">TECH BOARD PUNJAB</option>
                                        <option value="Technical Board sindh">TECH BOARD SINDH</option>
                                        <option value="Technical Board baluchistan">TECH BOARD BALUCHISTAN</option>
                                        <option value="Other">Other</option>
                                    </MySelect>

                                </div>

                            </div>



                            <div className='row'>
                                <div className='col-md-6  ' >
                                    <div className='d-flex flex-column mt-2'>

                                        <label >HSSC DMC</label>
                                        <input label="HSSC DMC" name="hsscDmc" type="file"
                                            ref={hsscDmcRaf}
                                            // hidden

                                            onChange={(event) => {
                                                formik.setFieldValue("hsscDmc", event.target.files[0]);
                                            }}

                                        />
                                        <KErrorMessage name='hsscDmc' />

                                    </div>
                                </div>
                                <div className='col-md-6 '>

                                    <div className='d-flex flex-column mt-2'>
                                        <label>HSSC Certificate</label>
                                        <input label="HSSC Certificate" name="hsscCertificate" type="file"
                                            ref={hsscCertificateRaf}
                                            // hidden

                                            onChange={(event) => {
                                                formik.setFieldValue("hsscCertificate", event.target.files[0]);
                                            }}

                                        />
                                        <KErrorMessage name='hsscCertificate' />
                                    </div>
                                </div>
                            </div>
                            <hr />


                            <div>
                                <AdmissionDeptt values={formik.values.interSubject} />
                            </div>

                            <div className='row'>
                                <div className='col-lg-4 col-sm-6'>

                                    <div id="my-radio-group">Hafiz Quran</div>
                                    <div role="group" aria-labelledby="my-radio-group">
                                        <label className='p-2'>
                                            <Field type="radio" name="hafizQuran" value="yes" />
                                            Yes
                                        </label>
                                        <label className='p-2'>
                                            <Field type="radio" name="hafizQuran" value="no" />
                                            No
                                        </label>

                                    </div>


                                </div>
                                <div className='col-lg-4 col-sm-6'>
                                    <div id="my-radio-group">Hostel Needed</div>
                                    <div role="group" aria-labelledby="my-radio-group">
                                        <label className='p-2'>
                                            <Field type="radio" name="hostalneed" value="yes" />
                                            Yes
                                        </label>
                                        <label className='p-2'>
                                            <Field type="radio" name="hostalneed" value="no" />
                                            No
                                        </label>

                                    </div>

                                </div>

                                <div className='col-lg-4 col-sm-6'>

                                    <div id="my-radio-group">Transport Needed</div>
                                    <div role="group" aria-labelledby="my-radio-group">
                                        <label className='p-2'>
                                            <Field type="radio" name="transportneed" value="yes" />
                                            Yes
                                        </label>
                                        <label className='p-2'>
                                            <Field type="radio" name="transportneed" value="no" />
                                            No
                                        </label>

                                    </div>

                                </div>
                                <MyCheckbox name="acceptedTerms">
                                    The above information is correct to the best of my knowledge.
                                </MyCheckbox>

                            </div>


                            <div className='d-flex  justify-content-center pb-3 ' >
                                <button className="btn btn-danger mt-3" type="reset">Reset</button>
                                {loading ?
                                    <button className="btn btn-primary mt-3" style={{ marginLeft: '10px', width: "80px" }} >
                                        Loading..
                                    </button>
                                    :
                                    loader ?
                                        <button className="btn btn-primary mt-3" style={{ marginLeft: '10px', width: "80px" }} >
                                            Loading..
                                        </button>
                                        :
                                        <button className="btn btn-primary mt-3" type="submit" style={{ marginLeft: '10px', width: "80px" }} >
                                            Submit
                                        </button>
                                }

                            </div>

                        </Form>

                    </div>
                )
                }
            </Formik >

        </>
    )

}
export default AdmissionForm