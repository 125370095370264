import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner'
import { API_URL } from "../../config"

const Messages = () => {
    const [loader, setLoader] = useState(false)
    const [datalist, setDatalist] = useState([])

    // console.log(datalist)
    var sorteddatalist = datalist && datalist.slice().reverse()
    // console.log(sorteddatalist)
    var serialno = 0
    const handleDelete = (_id) => {
        setLoader(true)
        fetch(`${API_URL}/admindeletemessage/${_id}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            toast.success("Deleted Successfully")

        }).catch(err => {

            toast.error("Server down")
        })
    }

    useEffect(() => {
        setLoader(true)
        fetch(`${API_URL}/contactusread`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },


        })
            .then(response => {

                return response.json();
            })
            .then(data => {
                setDatalist(data.resp)
                setLoader(false)
                // console.log(data)
            },
            ).catch(err => {
                setLoader(false)
                console.log(err)

            })


    }, [])



    return (
        <div className='container' style={{ marginTop: '150px' }}>
            <ToastContainer />
            <div className='row text-center'>
                <h3 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>Contact Messages</h3>

            </div>
            <div className='mt-5'>
                {loader ? <div className='' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ThreeDots color="#003396" height={80} width={80} />

                </div> :
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>ContactNo</th>
                                <th>Email</th>
                                <th>Time</th>

                                <th>Message</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sorteddatalist && sorteddatalist.map((item) => {
                                return (



                                    <tr key={item._id} >
                                        <td>{serialno = serialno + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.contactno}</td>
                                        <td>{item.email}</td>
                                        <td>{item.time}</td>
                                        <td style={{ scrollBehavior: 'auto' }}>{item.messageDetail}</td>
                                        <td >
                                            <button className='btn btn-danger' onClick={() => {
                                                window.confirm('Are you sure you want to delete this message?',) &&
                                                    handleDelete(item._id)
                                            }}>Delete</button>
                                        </td>

                                    </tr>


                                )
                            })}
                        </tbody>
                    </Table>
                }
            </div>
        </div>
    )
}

export default Messages