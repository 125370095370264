import React, { useEffect, useState } from 'react'
import { BsPencil } from 'react-icons/bs'
import { ThreeDots } from 'react-loader-spinner'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
import { API_URL } from "../../config"

import './AdminHome.css'
const AdminHome = () => {
    const [currentApplist, setCurrentApplist] = useState([])
    const [regApplist, setRegApplist] = useState([])
    const [totalApplist, setTotalApplist] = useState([])
    const [depttlist, setDepttlist] = useState([])
    const [formstatus, setFormstatus] = useState(false)
    const [fetchingcontrol, setFetchingcontrol] = useState(false)
    const [loader, setLoader] = useState(false)

    // console.log(currentApplist)
    // console.log(regApplist)
    // console.log(totalApplist)
    // console.log(loader)
    useEffect(() => {
        setLoader(true)
        fetch(`${API_URL}/formdisplayread`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem("token")
            },
        })
            .then(response => {
                // console.log(response)
                if (response.ok) {
                    return response.json();
                }

            })
            .then(data => {
                setFormstatus(data.resp.formdisplay)
                // console.log(data.resp.formdisplay)

            },
            ).catch(err => {

                console.log(err)

            })

        fetch(`${API_URL}/adminallapplyapplicant`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },


        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setLoader(false),
                    // toast.error("Server down")

                )
            })
            .then(data => {
                setTotalApplist(data.resp)
                setLoader(false)
            },
            );

        fetch(`${API_URL}/adminregisterapp`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },


        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setLoader(false),
                    console.log('error')

                )
            })
            .then(data => {
                setRegApplist(data.resp)
                setLoader(false)
            },
            ).catch(err => {
                setLoader(false)
                // toast.error("Server down")
            })

        fetch(`${API_URL}/adminallapplyapplicantcurrent`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },


        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setLoader(false),
                    console.log('error')

                )
            })
            .then(data => {
                setCurrentApplist(data.resp)
                setLoader(false)
            },
            ).catch(err => {
                setLoader(false)
                // toast.error("Server down")
            })

        fetch(`${API_URL}/adminregisterdeptt`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },


        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setLoader(false),
                    console.log('error')


                )
            })
            .then(data => {
                setDepttlist(data.resp)
                setLoader(false)
            },
            ).catch(err => {
                setLoader(false)
                console.log(err)
                toast.error("Server down")
            })


    }, [fetchingcontrol])
    const handlechangeform = () => {
        setLoader(true)
        var _id = '62a2c1376c01a706f805397b'
        fetch(`${API_URL}/formcontrol/${_id}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify({
                formdisplay: !formstatus

            })

        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(
                    setLoader(false),
                    // console.log('error')


                )
            })
            .then(data => {
                // console.log(data)
                setFetchingcontrol(!fetchingcontrol)
                toast.success("Change Successfully")
                setLoader(false)
            },
            ).catch(err => {
                setLoader(false)
                console.log(err)
                toast.error("Server down")
            })
    }
    return (
        <div className='container' >
            <ToastContainer />
            <div className='row mb-4' style={{ marginTop: '120px', color: ' #003396', fontWeight: 'bolder' }}>

                <h1 className='text-center'>Wellcome to Admin Dashboard</h1>
            </div>

            <div className='d-flex justify-content-end'>
                <button className='newbtnadminhome btn btn-success'><Link style={{ textDecoration: "none", color: 'white' }} to='/newsadd'>Add News</Link></button>
            </div>
            <div className='row mt-5 d-flex justify-content-end'>
                <div className='col-lg-12 mb-4 cardfulldiv'>
                    <div className='carddiv'>

                        <div className='carddivunder1'>
                            <h4 className='cardtext'>
                                {formstatus ? 'Admission Open' : 'Admission Off'}


                            </h4>

                        </div>
                        <div className='float-end' >
                            <div className='editdisplayhome d-flex justify-content-center' style={{
                                backgroundColor: 'green', width: '50px',
                                cursor: "pointer",
                                height: '50px'
                            }}
                                onClick={() => { window.confirm(`Are you sure to ${formstatus ? 'Admission Off' : 'Admission Open'}?`,) && handlechangeform() }
                                }

                            >

                                <p

                                    style={{ color: 'white', marginTop: '10px' }} >{formstatus ? 'OFF' : 'ON'}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {loader ? <div className='' style={{ display: 'flex', justifyContent: 'center' }}>
                <ThreeDots color="#003396" height={80} width={80} />

            </div> :
                <div className='row mt-5'>


                    <div className='col-lg-6 mb-4 cardfulldiv'>
                        <div className='carddiv'>
                            <Link to='/adminregisterapplist' >
                                <div className='carddivunder'>
                                    <h4 className='cardtext'>
                                        Register Applicants <br />
                                        {regApplist && regApplist.length}

                                    </h4>

                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4 cardfulldiv'>
                        <div className='carddiv'>

                            <Link to='/adminallapplist' >

                                <div className='carddivunder'>

                                    <h4 className='cardtext'>
                                        Total Apply <br />
                                        {totalApplist && totalApplist.length}
                                    </h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4 cardfulldiv'>
                        <div className='carddiv'>

                            <Link to='/admincurrapplist' >

                                <div className='carddivunder'>
                                    <h4 className='cardtext'>
                                        Current Apply <br />
                                        {currentApplist && currentApplist.length}
                                    </h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='col-lg-6 mb-4 cardfulldiv'>
                        <div className='carddiv'>
                            <Link to='/admindepttlist' >
                                <div className='carddivunder'>
                                    <h4 className='cardtext'>
                                        Total Departments <br />
                                        {depttlist && depttlist.length}
                                    </h4>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}

export default AdminHome