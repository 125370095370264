import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../config"
export const ApplyForm = createAsyncThunk(
    'ApplyForm', async (data) => {
        // console.log("this is data in assync thunk", data)
        const { pictureU, PicUrls, values,
            deptts1, deptts2, deptts3, deptts4, deptts5 } = data
        // console.log(deptts1, deptts2, deptts3, deptts4, deptts5)
        // const { cnicPic, domicilePic, hsscDmc, hsscCertificate } = values
        // console.log('this is destructure data in thunk', pictureU, PicUrls, values)
        const { email, fatherName, contactno, name, cnic,
            formStatus,

            session,
            Dob,
            gender,
            religion,
            nationality,
            fatherCnic,
            fatherOccuption,
            permanentAddress,
            postalAddress,
            hafizQuran,
            hostalneed,
            transportneed,
            matricRollno,
            matricBoardname,
            matricpassingyear,
            matricObtmarks,
            matricTmarks,
            matricSubject,
            interRollno,
            interBoardname,
            interpassingyear,
            interObtmarks,
            interTmarks,
            interSubject,
            acceptedTerms,
        } = values
        var hifazNo = false
        try {
            const res = await fetch(`${API_URL}/applyform`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify({
                    email, fatherName, contactno, name, cnic,
                    formStatus,
                    deptts1,
                    deptts2,
                    deptts3,
                    deptts4,
                    deptts5,
                    session,
                    Dob,
                    gender,
                    religion,
                    nationality,
                    fatherCnic,
                    fatherOccuption,
                    permanentAddress,
                    postalAddress,
                    hafizQuran,
                    hostalneed,
                    transportneed,
                    matricRollno,
                    matricBoardname,
                    matricpassingyear,
                    matricObtmarks,
                    matricTmarks,
                    matricSubject,
                    interRollno,
                    interBoardname,
                    interpassingyear,
                    interObtmarks,
                    interTmarks,
                    interSubject,
                    acceptedTerms,
                    pictureU,
                    PicUrls,
                    hifazNo
                })
            })
            const result = await res.json()
            // console.log('this is result', result)
            return result

        } catch (error) {
            // toast.error(error)
            console.log(error)
        }

    }
)