import React, { useState } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { setLogout } from '../../redux/features/UserSlice'
import './Navbarclass.css'

const Navbarclass = () => {

    const [collapse, setCollapse] = useState(true);

    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem("userdetail"))

    // console.log(user && user.status)
    const navigate = useNavigate()
    const logoutHandle = () => {
        localStorage.removeItem("userdetail")
        localStorage.removeItem("token")
        navigate('/')

        dispatch(setLogout())

    }
    const renderList = () => {
        // { console.log('user', user) }
        if (user && user.status === 'applicant') {
            return [
                <Nav className='me-auto ' key='1' >
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to='/' >Home</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/admform">Form</Nav.Link>,
                    <NavDropdown
                        className=' navbarposition'
                        id="nav-dropdown-dark-example"
                        title="Programs"
                        menuVariant="dark"
                    >
                        <NavDropdown.Item onClick={() => { navigate("/bsprograms"); setCollapse(!collapse) }} >BS Program</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { navigate("/msprograms"); setCollapse(!collapse) }} >MS Program</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { navigate("/frequentaskquestion"); setCollapse(!collapse) }} >FAQs</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { navigate("/criteria"); setCollapse(!collapse) }} >Eligibility Criteria</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { navigate("/scholarships"); setCollapse(!collapse) }} >Scholarships</NavDropdown.Item>

                    </NavDropdown>
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/documentation">Help</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/contactus">Contact Us</Nav.Link>
                    {/* <NavDropdown
                        className=' navbarposition'
                        id="nav-dropdown-dark-example"
                        title={user && user.email}
                        menuVariant="light"
                    >
                        <NavDropdown.Item onClick={() => { logoutHandle(); setCollapse(!collapse) }}>Logout</NavDropdown.Item>
                    </NavDropdown> */}
                </Nav>,
                <Nav className='ms-auto mt-1 d-flex' key='5'  >

                    <NavDropdown
                        id="nav-dropdown-dark-example"
                        title={user && user.email}
                        menuVariant="light"
                    >
                        <NavDropdown.Item onClick={() => { logoutHandle(); setCollapse(!collapse) }}>Logout</NavDropdown.Item>
                    </NavDropdown>

                </Nav>
            ]

        }
        else if (user && user.status === 'department') {
            return [
                <Nav className='me-auto' key='2'>
                    <Nav.Link as={Link} to='/' >Home</Nav.Link>,
                    <Nav.Link as={Link} to="/deptthome">Applicants List</Nav.Link>,
                    {/* <Nav.Link as={Link} to="#pricing">Contact Mail</Nav.Link>, */}

                </Nav>,
                <Nav className='ms-auto mt-1 d-flex' key='5'  >

                    <NavDropdown
                        id="nav-dropdown-dark-example"
                        title={user && user.email}
                        menuVariant="light"
                    >
                        <NavDropdown.Item onClick={() => { navigate("/depttupdatepassword"); setCollapse(!collapse) }}
                        >Change Password</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { logoutHandle(); setCollapse(!collapse) }}>Logout</NavDropdown.Item>

                    </NavDropdown>

                </Nav>


            ]
        }
        else if (user && user.status === 'admin') {
            return [
                <Nav className='ms-auto' key='3'>
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/"> Home</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/adminh"> Dashboard</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/contactmessages">Contact Mail</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/deletedapp">Deleted Log</Nav.Link>,
                    <button className='btn-danger'
                        onClick={() => { logoutHandle(); setCollapse(true) }}>LogOut</button>


                </Nav>
            ]
        } else {
            return [
                <Nav className='ms-auto' key='4' navbarScroll>
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to='/' >Home</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/admform">Apply</Nav.Link>,
                    {/* <NavDropdown
                        className=' navbarposition'
                        id="nav-dropdown-dark-example"
                        title="Departments"
                        menuVariant="dark"
                    >

                        <div className='d-flex flex-column p-1 navbardepttslinkdiv '  >

                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/17' >Agriculture </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/41' >Botany </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/42' >Biotechnology </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/18' >Computer Science </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/31' >Chemistry </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/19' > Economics </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/20' > English </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/21' > Geology</a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/22' > Management Sciences</a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/23' > Microbiology </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/24' > Pharmacy </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/25' > Sociology </a>
                            <a className='abcde' href='https://www.uoswabi.edu.pk/cmscategory/index/26' > Zoology </a>
                        </div>

                    </NavDropdown> */}
                    <NavDropdown
                        className=' navbarposition'
                        id="nav-dropdown-dark-example"
                        title="Programs"
                        menuVariant="dark"
                    >
                        <NavDropdown.Item onClick={() => { navigate("/bsprograms"); setCollapse(!collapse) }} >BS Program</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { navigate("/msprograms"); setCollapse(!collapse) }} >MS Program</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { navigate("/frequentaskquestion"); setCollapse(!collapse) }} >FAQs</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { navigate("/criteria"); setCollapse(!collapse) }} >Eligibility Criteria</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => { navigate("/scholarships"); setCollapse(!collapse) }} >Scholarships</NavDropdown.Item>

                    </NavDropdown>
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to="/documentation">Help</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to='/applogin' >Login</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to='/signup' >SignUp</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to='/aboutus' >AboutUs</Nav.Link>,
                    <Nav.Link as={Link} onClick={() => { setCollapse(true) }} to='/contactus' >ContactUs</Nav.Link>

                </Nav>
            ]
        }

    }

    return (
        <div className='container-fluid' >
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top"  >
                <Container>
                    {/* <Navbar.Brand >UOS</Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"
                        onClick={() => setCollapse(!collapse)}
                    />
                    <Navbar.Collapse id="responsive-navbar-nav"
                        style={{ display: collapse ? "none" : "block" }}
                    >

                        {renderList()}


                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Navbarclass