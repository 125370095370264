import React, { useEffect, useState } from 'react'
import './OurMissionV.css'
import pic from '../../assets/abcuos.jpg'
import { API_URL } from "../../config"
const OurMissionV = () => {
    const [newsdata, setNewsdata] = useState('')
    // console.log(newsdata.NewsMessage)
    var news = newsdata.NewsMessage
    useEffect(() => {

        fetch(`${API_URL}/newsmessageread`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Bearer " + localStorage.getItem("token")
            },
        })
            .then(response => {
                // console.log(response)
                if (response.ok) {
                    return response.json();
                }

            })
            .then(data => {
                setNewsdata(data.resp)
                // console.log(data)

            },
            ).catch(err => {

                console.log(err)

            })
    }, [])

    return (
        <div className='container'>


            <div className='row mt-5 admissionrowsystemdiv'  >
                <div className='col-lg-8 col-sm-12  admissiononlinesystemdiv'>
                    <h1 className='admissiononlinesystem'>
                        Online Admission System
                    </h1>
                </div>
                <div className='col-lg-4 col-sm-12 mt-5  marqueedivourmissiondiv'>
                    <div className='marqueedivourmission'>
                        <div className='marqueeheadingourmission'>
                            <h3>
                                News And Event
                            </h3>

                        </div>
                        <div className='marqueedivourmissiondiv'>

                            <marquee className='text-center' width="90%" direction="down" height="100%">
                                <h6 >
                                    Tender Notice
                                </h6>
                                {newsdata ?
                                    <h6>
                                        {newsdata.NewsMessage}
                                    </h6>
                                    : 'Loading...'}

                                {newsdata ?
                                    <h6>
                                        {newsdata.NewsMessage}
                                    </h6>
                                    : 'Loading...'}

                                {newsdata ?
                                    <h6>
                                        {newsdata.NewsMessage}
                                    </h6>
                                    : 'Loading...'}
                            </marquee>

                        </div>
                    </div>
                </div>
            </div>


            {/* This vission protion */}
            <div className='vissionfulldiv mt-5'>

                <div className='row vissionrowdiv' >
                    <div className='col-12 ourmissiondiv' >
                        <h3 >Our Mission And Vission</h3>
                    </div>
                    <div className='col-12 ourmissionparagraph'>
                        <p>

                            The University of Swabi aspires to be the leading university among the newly established universities of Pakistan,
                            renowned and recognized around the world for offering extensive academic and professional
                            programs of the ultimate quality, for its idiosyncratic style of learning that engage and organize students
                            and faculty for leadership roles in the society, and for being
                            a major national and international center of learning and research in the quest to improve human life.
                            Social and economic progress of society through ground breaking research, acquisition
                            and application of knowledge; and contributing
                            to the encroachment of learning and to the growth of human resources in Swabi,
                            Khyber Pakhtunkhwa and rest of the country.
                        </p>
                    </div>
                </div>
            </div>
            {/* This is Discover Campass */}
            <div className=' discoverfulldiv'>
                <div className='row discovercampass'>

                    <div className='col-lg-6 col-sm-12 discoverdiv'>
                        <h3 className='headingdiscover'>Discover The Campus</h3>
                        <div className='mt-5 discovercampassparagraph' >
                            <p>
                                University of Swabi was established in 2012 with the mission to advance knowledge and learning through
                                quality research and education for the whole nation. Currently, the university
                                comprises of two campuses, old campus and main campus having a combined area of 188 acres
                                nears M1 Motorway.
                                <br />
                                <br />
                                <br />
                                There are 27 Academic Departments at University of Swabi. Currently more than 4000 students are enrolled
                                in BS, Masters and MS/MPhil degree programs. There are one Boy’s, one Girl's
                                and one Staff Hostel at the campus. The Central Library consists of more than 20,000 books of different disciplines.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6 col-sm-12 discoverdiv'>

                        <img src={pic} className='discoverimg mt-5' />
                    </div>
                </div>

            </div>
            <div>
                <div className='row  mt-5' >
                    <div className='text-center'>

                        <h3 className='headingdiscover mb-4'>UOS STATISTICS</h3>
                    </div>

                    <div className='row' >


                        <div className='col-lg-3 col-md-6 col-sm-12 mt-4'>
                            <div className='d-flex justify-content-center'>

                                <div className='statisticscarddiv text-center ' style={{ height: '200px', width: '250px' }}>
                                    <h2 className='pt-4'>5000</h2>
                                    <p>
                                        <strong>Total Students </strong>
                                        enrolled in Last 4 years
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12 mt-4'>
                            <div className='d-flex justify-content-center'>
                                <div className='statisticscarddiv text-center ' style={{ height: '200px', width: '250px' }}>
                                    <h2 className='pt-4'>120</h2>
                                    <p>
                                        <strong>Total Faculty </strong>
                                        with Doctorate Degree's
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12 mt-4'>
                            <div className='d-flex justify-content-center'>
                                <div className='statisticscarddiv text-center ' style={{ height: '200px', width: '250px' }}>
                                    <h2 className='pt-4'>27</h2>
                                    <p>
                                        <strong>Total Departments  </strong>
                                        having number of programmes
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12 mt-4'>
                            <div className='d-flex justify-content-center'>
                                <div className='statisticscarddiv text-center ' style={{ height: '200px', width: '250px' }}>
                                    <h2 className='pt-4'>15</h2>
                                    <p>
                                        <strong>Total Colleges   </strong>
                                        Affiliated with Univeristy Of Swabi
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            {/* this is the Departments details  */}

            <div className='vissionfulldiv mt-5'>

                <div className='row vissionrowdiv' >
                    <div className='col-12 ourmissiondiv' >
                        <h3 >Details About Departments</h3>
                    </div>
                    <div className='col-12 ourmissionparagraph'>
                        <p>

                            The University of Swabi aspires "Departments serve as administrative structures." Departments are generally chaired by
                            a member of the department, who may be elected by the faculty of the department, appointed by the dean of the faculty, or assigned by simple rotation among the tenured faculty. The duties, importance, and power of the department chair vary widely among institutions and even among departments within an institution.
                            Social and economic progress of society through ground breaking research, acquisition
                            and application of knowledge; and contributing
                            to the encroachment of learning and to the growth of human resources in Swabi,
                            Khyber Pakhtunkhwa and rest of the country.<a href='https://www.uoswabi.edu.pk/cmscategory/index/3'>Read More...</a>
                        </p>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default OurMissionV