import React from 'react';
import { ErrorMessage, useField } from 'formik';

export const MySelect = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    // console.log(label)
    // console.log(field.name)
    // console.log(meta)

    return (
        <div>
            <label htmlFor={props.id || props.name}>{label}</label>
            <select {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error" style={{ color: 'red' }} >{meta.error}</div>
            ) : null}
        </div>
    );
};
// export const Field = ({ label, ...props }) => {
//     const [field, meta] = useField(props);
//     console.log(label)
//     console.log(field.name)
//     console.log(meta)

//     return (
//         <div>
//             <label htmlFor={props.id || props.name}>{label}</label>
//             <select {...field} {...props} />
//             {meta.touched && meta.error ? (
//                 <div className="error">{meta.error}</div>
//             ) : null}
//         </div>
//     );
// };

export const MyCheckbox = ({ children, ...props }) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    // console.log()
    // console.log(field.name)
    // console.log(meta)
    return (
        <div>
            <label className="checkbox-input">
                <input type="checkbox" {...field} {...props} />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="error" style={{ color: 'red' }}>{meta.error}</div>
            ) : null}
        </div>
    );
};