import React from 'react'
import './Aboutus.css'
const Scholarship = () => {
  return (
    <div>
      <div className='container-fluid allaboutusbanerfulldiv' >
        <div className='allaboutbanertext'>
          <h1>SCHOLARSHIPS</h1>
        </div>
      </div>
      <div className='container'>
        <div className='directorheadingaboutus mt-5'>
          <h4>
            SCHOLARSHIPS
          </h4>

        </div>
        <div className=' mt-4'>
          <p>
            The university facilitates students through below scholarships to apply for various departments:
          </p>
          <li>
            University Need-based Scholarships.
          </li>
          <li>
            University Merit-based Scholarships.
          </li>
          <li>
            MORA Scholarships.
          </li>
          <li>
            NBP Student Loan Scheme.
          </li>
          <li>
            NBP Student Loan Scheme.
          </li>
          <li>
            Pakistan Bait-ul-Maal Scholarships.
          </li>
          <li>
            HEC Need-based Scholarships,
          </li>
          <li>
            Diya.
          </li>
          <li>
            Hamdard Scholarships.
          </li>
          <li>
            Ehsas Scholarships.
          </li>
        </div>
      </div>
    </div>
  )
}

export default Scholarship