import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner'
import { API_URL } from "../../config"

const Deletedapplog = () => {
    const [loader, setLoader] = useState(false)
    const [datalist, setDatalist] = useState([])
    const [datafetchcontrol, setDatafetchcontrol] = useState(false)
    // console.log(datalist)
    var sorteddatalist = datalist && datalist.slice().reverse()
    // console.log(sorteddatalist)
    var serialno = 0


    useEffect(() => {
        setLoader(true)
        fetch(`${API_URL}/deletedapplogread`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },


        })
            .then(response => {

                return response.json();
            })
            .then(data => {
                setDatalist(data.resp)
                setLoader(false)
                // console.log(data)
            },
            ).catch(err => {
                setLoader(false)
                console.log(err)

            })


    }, [datafetchcontrol])



    return (
        <div className='container' style={{ marginTop: '150px' }}>
            <ToastContainer />
            <div className='row text-center'>
                <h3 style={{ color: "#003396", textTransform: 'uppercase', textShadow: '3px 3px 3px #888888' }}>Deleted Applicants</h3>

            </div>
            <div className='mt-5'>
                {loader ? <div className='' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ThreeDots color="#003396" height={80} width={80} />

                </div> :
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>CNIC</th>
                                <th>ContactNo</th>
                                <th>Email</th>
                                <th>Form Status</th>
                                <th>Session</th>
                                <th>Aggregate</th>
                                <th>Deleted Time</th>


                            </tr>
                        </thead>
                        <tbody>
                            {sorteddatalist && sorteddatalist.map((item) => {
                                return (



                                    <tr key={item._id} >
                                        <td>{serialno = serialno + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.fatherName}</td>
                                        <td>{item.cnic}</td>
                                        <td>{item.contactno}</td>
                                        <td>{item.email}</td>
                                        <td>{item.formStatus.charAt(0).toUpperCase() + item.formStatus.slice(1)}</td>
                                        <td>{item.session}</td>
                                        <td>{item.aggregate.toFixed(2)}</td>
                                        <td>{item.time}</td>


                                    </tr>


                                )
                            })}
                        </tbody>
                    </Table>
                }
            </div>
        </div>
    )
}

export default Deletedapplog