import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../config'
export const DepttApplist = createAsyncThunk(
    'DepttApplist', async (data) => {
        // console.log("this is data", data)
        const { depttname, session, formStatus } = data
        // console.log('this is destructure data', depttname, session, formStatus)

        try {
            const res = await fetch(`${API_URL}/depttstudentslist`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt")
                },
                body: JSON.stringify({
                    depttname, session, formStatus
                })
            })
            const data = await res.json()



            return data

        } catch (error) {
            // toast.error(error)
            console.log(error)

        }

    }
)